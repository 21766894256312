import React from 'react';
import Section from 'Section';
import Header from 'Header';
import Link from 'Link';
import Icon from 'Icon';
import Button from 'Button';
import { connect } from 'react-redux';
import orderActions from 'actions/order.js';
import calcActions from 'actions/calc.js';
import './style.scss';

class Promo extends React.Component {

  constructor(props){
    super(props);
    // const opacity = parseFloat(window.getComputedStyle(pano).opacity) || 1;
    const frame = () => {
      // const { pano } = this.refs;
      // const rect = pano.getBoundingClientRect();
      // pano.style.transform = "translateY(" + (-rect.top / 3) + "px)";
      // // pano.style.opacity = opacity + rect.top / rect.height;
    };
    this.raf = () => requestAnimationFrame(frame);
  }

  componentDidMount() {
    document.addEventListener("scroll", this.raf, {passive: true});
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.raf, false);
  }

  render() {
    const image = require("assets/images/promo-bg.jpg");
    const { props } = this;
    return (
      <div className="promo" style={image ? {backgroundImage: `url(${image.placeholder})`} : null } >
        <iframe ref="pano" className="promo__pano" title="Панорама" src="/projects/promo/?fov=90&lon=-30.92&id=11" />
        <Header right={(
          <div>
            <Link className="phone" href="tel:+74950158360">+7 (495) 015 83 60</Link>
            <Link className="email" href="mailto:mail@tour-360.ru">mail@tour-360.ru</Link>
          </div>
        )}/>
        <Section>
          <h1>Снимаем панорамы 360º<br/> и создаем 3D туры для бизнеса</h1>
          {/*<h1>Создаем 3D тура для бизнеса</h1>*/}
          <p>
            Публикуем на Google и Яндекс картах, на вашем сайте,<br />в Instagram, YouTube, ВКонтакте и Facebook
          </p>
          <Button backlight onClick={props.openCalcPopup} big>Рассчитать стоимость</Button>
          <a href="#projects" title="Прокрутить вниз" className="promo__more">
            <Icon name="more" />
          </a>
        </Section>
      </div>
    )
  }
}

export default connect(null, (dispatch) => ({
  ...orderActions(dispatch),
  ...calcActions(dispatch)
}))(Promo);
