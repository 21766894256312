import React from 'react'
import './style.scss';
import Section from 'Section';
import Copyright from 'Copyright';
import Logo from 'Logo';
import Link from 'Link';
import SocialNetworks from 'SocialNetworks';
import { Row, Col } from 'Grid';

export default class Footer extends React.Component {
  render () {
    return (
      <footer className="footer">
        <Section>
          <Row>
            <Col className="footer__main-column">
              <Logo link small accent>Tour-360.ru</Logo>
              <p>
                Съемка 3D панорам<br />и создание виртуальных туров 360º
              </p>
              <SocialNetworks />
            </Col>
            <Col className="footer__links-column">
              <b>Информация</b>
              <Link to="/projects">Наши работы</Link>
              <Link to="/prices">Цены</Link>
              <Link to="/contacts">Контакты</Link>
              <Link to="/faq">Вопросы и ответы</Link>
              <Link to="/map">Карта проектов</Link>
              {/*<Link to="/google">Программа от Google</Link>*/}
              {/*<Link to="/affiliateprogram">Партнерская программа</Link>*/}
            </Col>
            <Col className="footer__links-column">
              <b>Документы</b>
              <Link to="/requisites">Реквизиты</Link>
              <Link href="https://yandex.ru/maps/org/tour_360_ru/83334047999/?add-review=true" target="_blank">Оставить отзыв</Link>
            </Col>
            <Col className="footer__links-column">
              <b>Контакты</b>
              <p>
                г. Москва, Автозаводская улица,<br />дом 14, офис 907
              </p>
              <Link href="mailto:mail@tour-360.ru">mail@tour-360.ru</Link>
              <Link href="tel:+74950158360">+7 (495) 015 83 60</Link>
            </Col>
          </Row>
          <p><Copyright /></p>
        </Section>
      </footer>
    )
  }
}
