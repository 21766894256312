import React from 'react'
import './style.scss';

var translit = require('translit')(require('translit-russian'));

// import cn from 'classnames'

const titles = {
  1: (props) => <h1 {...props}>{props.children}</h1>,
  2: (props) => <h2 {...props}>{props.children}</h2>,
  3: (props) => <h3 {...props}>{props.children}</h3>,
  4: (props) => <h4 {...props}>{props.children}</h4>,
  5: (props) => <h5 {...props}>{props.children}</h5>,
  6: (props) => <h6 {...props}>{props.children}</h6>,
}

export default ({ children, id = false, lavel = 1 }, props) => {
  id = id || translit(children).replace(/ /g, '-');


  const Title = titles[lavel];

  const handleClick = () => {
    window.location.hash = id;
  }

  return (
    <Title className="title" id={id} onClick={handleClick}>{children}</Title>
  );

}
