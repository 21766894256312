import React from 'react';
import Icon from 'Icon';
import cn from 'classnames';

import './style.scss';

export default (props) => {
  return (
    <span onClick={props.onClick} title={props.title} className={cn("tag", {'tag--active': !!props.active, })}>
      {props.children}
      {props.active && <Icon className="tag__cancel" name="cross" />}
    </span>
  );
}
