import React from 'react';
import Section from 'Section';
import Link from 'Link';
import { Row, Col } from 'Grid';
import Page from 'components/Widgets/Page';

import './style.scss';

export default (props) => {
  return (
    <Page
      title="Реквизиты"
      description="Реквизиты студии Tour-360, ИП Бородин Максим Константинович"
      keywords={['Документы', 'Реквизиты', 'ИП']}
    >
      <Section>
        <h1>Реквизиты</h1>
        <Row className="requisites-page__requisites">
          <Col>
            <h2>ИП Бородин Максим Константинович</h2>
            <p>
              Свидетельство о регистрации физического лица в качестве ИП<br />
              от «9» февраля 2016 г. серия 77 № 017710200
            </p>
            <p>
              <b title="Идентификационный номер налогоплательщика" class="help">ИНН </b>772390615212<br />
              <b title="Основной государственный регистрационный номер индивидуального предпринимателя" class="help">ОГРНИП </b>316774600096240<br />
              <b title="Общероссийский классификатор предприятий и организаций" class="help">ОКПО </b>0202773469<br />
            </p>
            <p>
              <b title="Общероссийский классификатор территорий муниципальных образований" class="help">ОКТMО </b>45396000<br />
              <b title="Общероссийский классификатор видов экономической деятельности" class="help">ОКВЭД </b><span title="Деятельность в области фотографии" class="help">74.20</span><br />
              <b title="Регистрационный номер ПФР" class="help">Рег. № ПФР </b>087506011404<br />
            </p>
            <p>
              Телефон <Link href="tel:+74950158360">+7 (495) 015 83 60</Link><br />
              Сайт <Link href="//tour-360.ru">tour-360.ru</Link><br />
              E-mail <Link href="mailto:mail@tour-360.ru">mail@tour-360.ru</Link><br />
            </p>
          </Col>
          <Col>
            <h2>Счет</h2>
            <p>
              <b>Рac/c </b>40802810102360000349 в АО «АЛЬФА-БАНК»<br />
              <b>Кор/с </b>30101810200000000593 в ГУ Банка России по ЦФО<br />
              <b>ИНН </b>772816897<br />
              <b>БИК </b>044525593
            </p>
          </Col>
        </Row>
      </Section>
    </Page>
  )
}
