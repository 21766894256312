import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import TextField from 'TextField';
import TextArea from 'TextArea';
import Message from 'Message';
import Button from 'Button';
import Range from 'Range';
import Price from 'Price';
import Checkbox from 'Checkbox';
import Tabs from 'Tabs';
import { connect } from 'react-redux';
import tariffs from 'data/tariffs.js';

const selector = formValueSelector('calc');

const required = value => value ? undefined : 'Required';

const renderRange = (field) => (
  <Range {...field.input} formatLabel={field.formatLabel} minValue={field.minValue} maxValue={field.maxValue} />
)

const renderTabs = (field) => (
  <Tabs {...field.input} tabs={field.tabs} />
)


const renderCheckbox = (field) => (
  <Checkbox {...field.input} />
)

const renderInput = (field) => (
  <TextField {...field.input} placeholder={field.placeholder} type={field.type} error={field.meta.touched && field.meta.error} />
);

const renderTextAre = (field) => (
  <TextArea {...field.input} placeholder={field.placeholder} error={field.meta.touched && field.meta.error} />
);

class OrderForm extends Component {

  state = {
    displayOrderForm: false
  }

  render() {
    const { props } = this;
    const { value } = props;

    const tariffPrice = tariffs.filter( t => t.id === (value.tariff || 'standart'))[0].price;
    const panos = value.type === "panos" ? value.panos : Math.ceil(value.square / 10);

    const services = [
      {
        name: "google",
        title: 'на Google maps',
        price: 1000
      },
      {
        name: "yandex",
        title: 'на Яндекс картах',
        price: 1000
      },
      {
        name: "site",
        title: 'на вашем сайте',
        price: 0
      },
      {
        name: "facebook",
        title: 'В Facebook',
        price: 2000
      },
      {
        name: "instagram",
        title: 'В Instagram',
        price: 1500
      },
      {
        name: "vk",
        title: 'В ВКонтакте',
        price: 2000
      }
    ];

    const servicesPrice = services.map(s => {
      return value[s.name] ? s.price : 0;
    }).reduce((a, b) => a+b , 0);

    const totalPrice = panos * tariffPrice + servicesPrice;

    props.change('totalPrice', totalPrice);
    props.change('yandexPrice', value.yandex);

    return (
      <form className={props.className} onSubmit={props.handleSubmit}>
        <Field name="type" component={renderTabs} tabs={[
          { value: "panos", label: "По количеству панорам" },
          { value: "square", label: "По площади" }
        ]} />

        <h3>{value.type === "panos" ? 'Укажите количество панорам' : 'Укажите площадь помещения'}</h3>

        {value.type === "panos" ? (
          <Field name="panos" minValue={1} maxValue={64} component={renderRange} />
        ) : (
          <div>
            <Field name="square" formatLabel={v => v.toLocaleString('ru') + ' м²'} minValue={10} maxValue={1000} component={renderRange} />
            <p>Ориентировочно потребуется снять {panos} панорам</p>
          </div>
        )}

        <h3>Выберите тариф</h3>
        <Field name="tariff" component={renderTabs} tabs={tariffs.slice(0,3).map(t => ({
          label: t.name,
          value: t.id,
          price: t.price,
          title: t.description
        }))} />
        <small className="calc-popup__tariff-info">Стоимость съемки одной панорамы</small>

        <h3>Способ публикации тура</h3>

        <div className="services-list">
          {services.map(s => (
            <label><Field name={s.name} component={renderCheckbox} /> {s.title} <Price>{s.price}</Price></label>
          ))}
        </div>

        <div className="calc-popup__price">
          <Price>{totalPrice}</Price>
          <p> — Стоимость разработки и публикации 3D тура</p>
        </div>

        {!this.state.displayOrderForm ? (
          <div className="calc-popup__order-button">
            {/*<Button type="submit">Получить коммерческое предложение</Button>*/}
            <Button onClick={() => this.setState({displayOrderForm: true})}>Заказать за <Price>{totalPrice}</Price></Button>
          </div>
        ) : (
          <div className="calc-popup__order-form">
            <h3>Оформление зкакза</h3>
            <Field placeholder="Ваше имя" validate={required} name="name" component={renderInput} type="text" />
            <Field placeholder="Ваш телефон или e-mail" validate={required} name="contact" component={renderInput} type="text" />
            <Field placeholder="Коротко о вашем проекте (не обязательно)"  name="description" component={renderTextAre} />
            <Button type="submit">Отправить заказ</Button>
          </div>
        )}
        { props.error && <Message type="error">{props.error}</Message>}
      </form>
    )
  }
}

export default connect(
  state => ({
    value: selector(state, 'panos', 'square', 'tariff', 'type', 'yandex', 'google', 'vk', 'facebook', 'instagram', 'site' ),
    initialValues: {
      tariff: 'standart',
      panos: 5,
      square: 120,
      type: 'panos',
      google: true,
      yandex: true,
    }
  })
)(
  reduxForm({
    form: 'calc',
    destroyOnUnmount: false
  })(OrderForm)
);
