import ReactGA from 'react-ga';
import ym from 'react-yandex-metrika';

export default (category = false, label = false) => {
  ReactGA.event({
    action: 'click',
    category: category,
    label: (label || "").toString(),
  });

  ym('reachGoal', category, label);

  window.fbq && window.fbq('trackCustom', category, label);
}
