import React from 'react'
import './style.scss';
import ProjectCard from 'ProjectCard';
import ProjectPopup from 'ProjectPopup';
import Button from 'Button';
import cn from 'classnames';
import goal from 'libs/goal.js';

export default class Gallery extends React.Component {


  state = {
    popup: {
      visible: false
    },
    items: this.props.limit,
  };

  handleProjectClick = (id, key) => (e) => {

    goal('popup-projects', id);
    const project = this.props.projects[key];
    e.preventDefault();

    if (window.innerWidth > 600) {
      this.setState({popup: { ...project, id: key, visible: true }});
    } else {
      window.location.href = project.altUrl || project.url;
    }
  };

  handleProjectSelect = (b) => {
    const id = this.state.popup.id + b;
    return id < this.props.projects.length & id >= 0 ? () => {
      this.setState({popup: { ...this.state.popup, ...this.props.projects[id], id}});
    } : false;
  };

  handlePopupClose = () => {
    this.setState({popup: {visible: false}});
  }

  handleLoadMore = () => {
    this.setState({ items: this.state.items + this.props.limit});
  }

  render () {
    const { props, state } = this;
    return (
      <div className="gallery__wrapper">
        {state.popup.visible ? <ProjectPopup
          { ...state.popup }
          onClose={this.handlePopupClose}
          prew={this.handleProjectSelect(-1)}
          next={this.handleProjectSelect(1)}
        /> : false}
        <div className={cn("gallery", {"gallery--simple": this.props.simple})}>
          {props.projects.slice(0, (this.props.limit ? state.items : undefined )).map((p, key) => (
            <ProjectCard {...p} onSelectTag={this.props.onSelectTag} key={p.id} onClick={this.handleProjectClick(p.id, key)} className={p.displaySize} />
          ))}
        </div>
        { this.props.limit && props.projects.length > state.items && (
          <Button lite className="gallery__load-more" onClick={this.handleLoadMore}>Показать еще работ</Button>
        )}
      </div>
    )
  }
}
