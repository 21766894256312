import React from 'react';
import Header from 'Header';
import Button from 'Button';
import { connect } from 'react-redux';
import orderActions from 'actions/order.js';

export default connect(null, orderActions)(props => {
  return (
    <Header type={props.type} right={(
      <Button small onClick={props.openOrderPopup}>Заказать тур</Button>
    )}></Header>
  )
});
