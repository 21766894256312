import React from 'react';
import Body from 'Body';
import { Helmet } from "react-helmet";
import { asyncComponent } from 'react-async-component';

import './style.scss';

const Agreement = asyncComponent({
  resolve: () => import('./Agreement')
});

export default () => (
  <Body className="agreement-page">
    <Helmet>
      <title>Политика обработки персональных данных</title>
      <meta name="description" content="Политика обработки персональных данных" />
    </Helmet>
    <Agreement />
  </Body>
)
