import React from 'react';
import Page from 'components/Widgets/Page';
import Section from 'Section';
import Browser from './components/Browser';
import Cardboard from './components/Cardboard';
import Iphone from './components/Iphone';
import Code from 'Code';
import Title from 'Title';
import TourScreen from './components/TourScreen';
import Pluses from './components/Pluses';
import Gallery from 'components/Widgets/Gallery';
import './style.scss';

import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

export default class Player extends React.Component {

  state = {
    projects: [],
  }

  componentDidMount() {
    import("components/Pages/Projects/projects.js").then((m) => {
      this.setState({
        projects: m.default.filter(p => [8,83,143,54,4,80].includes(p.id))
      })
    })
  }

  render () {
    return (
      <ParallaxProvider>
        <Page
          className="player-page"
          title="Плеер виртуальных туров"
          description="Плеер виртуальных туров Tour-player"
          keywords={['Плеер виртуальных туров', 'Виртуальный тур']}
        >
          <Section className="player-page__promo">

            <h1>Плеер виртуальных туров</h1>
            <h4>Гибкое решение для создания и комфортного просмотра виртуальных туров на ПК, телефоне и в VR очках</h4>

            <div className="player-page__player">
              <Parallax y={[-15, 0]}>
                <Browser>
                  <iframe title="tour" src="/projects/loft904/player.html?fov=75&lat=0&lon=0&id=9" />
                </Browser>
              </Parallax>
              <Parallax className="cardboard__wrapper" y={[5, 0]}>
                <Cardboard>
                  <Parallax y={[-10, 0]}>
                    <TourScreen />
                  </Parallax>
                </Cardboard>
              </Parallax>
              <Parallax className="iphone__wrapper" y={[-10, 0]}>
                <Iphone>
                  <TourScreen />
                </Iphone>
              </Parallax>
            </div>
          </Section>

          <Section className="player-page__description">
            <p>Для того чтобы отснятые 3D панорамы было можно удобно просматривать и навигироваться между ними, мы разработали свой плеер виртуальных туров – <strong>Tour-player</strong>.  Гибкость плеера позволяет нам разрабатывать виртуальные тура любой сложности.</p>
            <p>Мы постоянно дополняем наш плеер новыми функциями, и оптимизируем его работу для уже существующих туров.</p>
          </Section>

          <Pluses />

          <div className="player-page__gallery">
            <Section>
              <Title id="logos" lavel={2}>Примеры</Title>
            </Section>
            {this.state.projects && <Gallery projects={this.state.projects} simple /> }

          </div>

          <Section className="player-page__code">
            <Title id="logos" lavel={2}>Использование</Title>
            <p>Для начала работы вставьте следующий код к себе на страницу:</p>
            <p>между тегов <b>head</b>:</p>
            <Code lang="html">{
  `<link rel="stylesheet" href="//tour-360.ru/tour-player/2.2.2/tour-player.css">
  <script src="//tour-360.ru/tour-player/2.2.2/tour-player.js"></script>`
            }</Code>

            <p>Перед тегом <b>{`</body>`}</b>:</p>
            <Code lang="html">{
  `<script>
    Tour.init('manifest.json', {
      controlPanel: false,
      gallery: true
    });
  </script>`
            }</Code>

            <Title id="tour.init" lavel={3}>Tour.init(manifest, options)</Title>
            <p>После подключения скриптом в глобальной области видимости появиться объект <b>Tour</b>. Для инициализации тура необходимо выполнить функцию <b>Tour.init()</b> c двумя параметрами:</p>
            <ul>
              <li><b>manifest</b>, Строка с именем файла описания тура</li>
              <li><b>options</b>, Объект опций тура</li>
            </ul>

            <Title id="tour.init" lavel={3}>Доступные опции</Title>
            <table>
              <tr>
                <th>Опция</th>
                <th>Значение по умолчанию</th>
                <th>Описание</th>
              </tr>
              <tr>
                <td><b>kineticRotateSpeed</b></td>
                <td>0.1</td>
                <td>Кинетическая скорость вращение панорамы</td>
              </tr>
              <tr>
                <td><b>autorotationSpeed</b></td>
                <td>-0.5</td>
                <td>Скорость автоматического вращения</td>
              </tr>
              <tr>
                <td><b>transition</b></td>
                <td>true</td>
                <td>Плавные переходы</td>
              </tr>
              <tr>
                <td><b>mouseMenu</b></td>
                <td>true</td>
                <td>Меню мыши (true — Показывать, false — не показывать, undefined — Отменить)</td>
              </tr>
              <tr>
                <td><b>controlPanel</b></td>
                <td>true</td>
                <td>Панель управления</td>
              </tr>
              <tr>
                <td><b>scaleControl</b></td>
                <td>true</td>
                <td>Возможность масштабировать (приближать)</td>
              </tr>
              <tr>
                <td><b>autorotationTimeout</b></td>
                <td>false</td>
                <td>Время бездействия для автовращения в миллисекундах</td>
              </tr>
              <tr>
                <td><b>gallery</b></td>
                <td>flase</td>
                <td>Галерея</td>
              </tr>
            </table>

            <Title id="tour.init" lavel={3}>Подписания на события</Title>
            <p>Для подписания на события тура используется функция <b>Tour.on()</b> принимающая атрибута: имя события (строка) и функцию callback</p>
            <Code lang="javascript">{
  `Tour.on('load', function(){
    console.log('load!')
  });`}
            </Code>

            <Title id="tour.init" lavel={4}>Возможные события:</Title>
            <ul>
              <li><b>load</b> – полная загрузка тура</li>
              <li><b>options</b> – переход между панорамами</li>
              <li><b>render</b> – отрисовка сцены</li>
              <li><b>touchstart</b> – начала вращения панорамы</li>
              <li><b>touchmove</b> – начала вращения панорамы</li>
              <li><b>touchend</b> – окончания вращения панорамы</li>
              <li><b>moveView</b> – вращение камеры</li>
            </ul>
          </Section>
        </Page>
      </ParallaxProvider>
    )
  }
}
