import React from 'react';
import Logo from 'Logo';
import Section from 'Section';
import Link from 'Link';
import cn from 'classnames';
import './style.scss';

export default class Header extends React.Component {
  state = {
    menu: false
  }

  toggleMenu() {
    this.setState({menu: !this.state.menu});
  }

  render() {
    const { props } = this;
    return (
      <header className={cn("header", this.props.type, {"header--menu-open": this.state.menu})}>
        <Section>
          <Link to="/"><Logo small>Tour-360.ru</Logo></Link>
          <div onClick={this.toggleMenu.bind(this)} className="header__menu-button">
            <span />
            <span />
            <span />
          </div>
          <nav>
            <ul>
              <li><Link to="/projects">Работы</Link></li>
              <li><Link to="/prices">Стоимость</Link></li>
              <li><Link to="/contacts">Контакты</Link></li>
              <li className="header__sub-menu">
                <span>Еще</span>
                <ul>
                  <li><Link to="/faq">Вопросы и ответы</Link></li>
                  <li><Link to="/requisites">Реквизиты</Link></li>
                  <li><Link to="/interior-photography">Интерьерная съемка</Link></li>
                  <li><Link to="/map">Карта проектов</Link></li>
                  <li><Link to="/player">Плеер виртуальных туров</Link></li>
                  {/*<li><Link to="/google">Программа от Google</Link></li>*/}
                  {/*<li><Link to="/affiliateprogram">Партнерская программа</Link></li>*/}
                </ul>
              </li>
            </ul>
          </nav>
          <div className="header__right">{props.right}</div>
        </Section>
      </header>
    )
  }
}
