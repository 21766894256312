import React from 'react';
import Dialog from 'Dialog';
import Icon from 'Icon';
import './style.scss';


export default class ProjectPopup extends React.Component {

  handleClose = () => {
    this.props.onClose();
  }

  handlePrev = e => {
    e.stopPropagation();
    this.props.prew();
  }

  handleNext = e => {
    e.stopPropagation();
    this.props.next();
  }

  render () {
    const { props } = this;
    return (
      <Dialog className="project-popup" onClose={this.handleClose}>
        <div onClick={this.handleClose} className="project-popup__close-button">
          <Icon name="cross" />
        </div>
        <iframe title={props.title} className="project-popup__iframe" src={props.url} allowFullScreen />

        {props.prew && <div onClick={this.handlePrev} className="project-popup__prev" title="Предыдущий проект">
          <Icon name="arrow_left" />
        </div>}

        {props.next && <div onClick={this.handleNext} className="project-popup__next" title="Следующий проект">
          <Icon name="arrow_right" />
        </div>}

        <div className="project-popup__meta">
          <Icon name="location" />
          <b>{props.title}</b>
          <p>{props.description}</p>
          <div></div>
        </div>
      </Dialog>
    )
  }
}
