import './styles.scss';

import React,{ memo } from 'react';
import Body from 'Body';
import Promo from "../../Main/Promo";
import Gallery from "../../../Widgets/Gallery";
import projects from "../../Main/projects";
import Pluses from "../../Main/Pluses";
import Calc from "../../Main/Calc";
import Steps from "../../Main/Steps";
import Tariffs from "../../Prices/Tariffs";
import Section from 'Section';
import Offer from "../../Main/Offer";
import Logos from "../../Main/Logos";
import Page from "../../../Widgets/Page";

export default memo(() => {
  return (
    <Page
      title="Блог студии Tour-360.ru"
      description="Блог студии виртуальных туров Tour-360.ru"
      className="blog-article"
    >
      <Section>
        <h1>123</h1>
      </Section>
    </Page>
  )
})
