import React from 'react';
import cn from 'classnames';
import './style.scss';

export default (props) => {

  return (
    <input
      className={cn("textfield", {'textfield--error': props.error})}
      {...props} type={props.type || "text"}
    />
  )
};
