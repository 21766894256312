import React from 'react';
import Page from 'components/Widgets/Page';
import Promo from './Promo';
import Steps from './Steps';
import Pluses from './Pluses';
import Logos from './Logos';
import Tariffs from '../Prices/Tariffs';
import Offer from './Offer';
import Calc from './Calc';
import Section from 'Section';
import Title from 'Title';
import Link from 'Link';
import Gallery from 'components/Widgets/Gallery';
import projects from "./projects.js";

import './style.scss';

export default (props) => {
  return (
    <Page
      title="Заказать виртуальный тур – Для вашего бизнеса"
      description="Tour-360.ru – Профессиональная съемка 3D панорам и создание виртуальных туров 360 градусов. Публикация в социальных сетях, на картах Google и Яндекса"
      keywords={['съёмка', 'виртуальные панорамы', 'интерактивные фототуры', "создание виртуальных туров", "сферические", "панорамы", "заказать", "3d панорамы", "быстро", "качественно", "бизнес"]}
      className="main-page"
      header={false}
    >
      <Promo />
      <Section>
        <Title id="projects" lavel={2}>Наши работы</Title>
        <p>Наша команда фотографов отсняла более 5166 панорам на 225 объектах. Посмотрите и оцените качество наших работ.</p>
        <Gallery projects={projects} handleProjectClick={() => {}} />
        <div className="main-page__view-more">
          <Link to="/projects">Смотреть все работы</Link>
        </div>
      </Section>
      <Pluses />
      <Calc />
      <Steps />
      <Section>
        <Title id="price" lavel={2}>Стоимость</Title>
        <p>Вы платите только за создание виртуального тура, размещение и использование — бесплатно! Виртуальный тур состоит из панорам, чем больше площадь, тем больше панорам нужно снять. Для вас на выбор 4 варианта качества.</p>
        <p>Цена указана за съемку одной панорамы.</p>
        <Tariffs />
      </Section>
      <Offer />
      {/*<Section>
        <Title id="projects" lavel={2}>Высочайшее качество</Title>
        <iframe
          allowFullScreen
          style={{height: "40em", width: '100%', border: 'none'}}
          src="https://tour-360.ru/projects/loft904/?fov=42.8&lat=-18.49&lon=-116.17&id=2"
        />
      </Section>*/}
      <Logos />
    </Page>
  )
}
