import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from 'TextField';
// import Link from 'Link';
import TextArea from 'TextArea';
import Message from 'Message';
import Button from 'Button';
import { Row, Col } from 'Grid';


const required = value => value ? undefined : 'Required';

const renderInput = (field) => (
  <TextField {...field.input} placeholder={field.placeholder} error={field.meta.touched && field.meta.error} />
);

const renderTextAre = (field) => (
  <TextArea {...field.input} placeholder={field.placeholder} error={field.meta.touched && field.meta.error} />
);

class OrderForm extends Component {
  render() {
    const { props } = this;
    return (
      <form className={props.className} onSubmit={props.handleSubmit}>
        <Row>
          <Col><Field placeholder="Ваше имя" validate={required} name="name" component={renderInput} type="text" /></Col>
          <Col><Field placeholder="Ваш телефон или e-mail" validate={required} name="contact" component={renderInput} type="text" /></Col>
        </Row>
        <Row>
          <Col><Field placeholder="Коротко о вашем проекте (не обязательно)"  name="description" component={renderTextAre} /></Col>
        </Row>
        <Button type="submit">Отправить</Button>
        {/*<Row className="agreement__row">
          <Col>
            <Button type="submit">Отправить</Button>
          </Col>
          <Col className="agreement__col"><small>Отправляя форму вы даёте <Link target="_blank" href="/agreement">согласие на обработку персональных данных</Link>.</small></Col>
        </Row>*/}
        { props.error && <Message type="error">{props.error}</Message>}
      </form>
    )
  }
}

export default reduxForm({
  form: 'order',
  destroyOnUnmount: false
})(OrderForm)
