import React from 'react';
import Section from 'Section';
import Title from 'Title';
import Button from 'Button';

import { connect } from 'react-redux';
import orderActions from 'actions/order.js';

import './style.scss';


export default connect(null, orderActions)(props => (
  <Section className="offer">
    <Title id="offer" lavel={2}>Доверьтесь профессионалам</Title>
    <p>Закажите создание <strong>виртуального тура</strong> у нас.</p>
    <Button onClick={props.openOrderPopup}>Заказать тур</Button>
  </Section>
));
