import React from 'react';
import cn from 'classnames';
import './style.scss';

export default props => (
  <span className={cn("checkbox", props.className, {"checkbox--error": props.error})}>
    <input checked={props.value} onChange={props.onChange} className="checkbox__input" type="checkbox" />
    <div className="checkbox__box" />
  </span>
)
