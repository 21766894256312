import React from 'react';
import Page from 'components/Widgets/Page';
import { GoogleMap, Marker } from 'GoogleMap';
import ProjectPopup from 'ProjectPopup';
import Link from 'Link';
import Tabs from 'Tabs';
import goal from 'libs/goal.js';
import Section from 'Section';
import Title from 'Title';
import moment from 'moment';
import ReactTooltip from 'react-tooltip'


import './style.scss';

const getPanoPoints = (obj) => {
  if (!obj) return 0;
  return Object.keys(obj).map(key => {
    if (Array.isArray(obj[key])) {
      return obj[key].map(p => p.panoPoints).reduce((a,b) => a+b);
    } else {
      return getPanoPoints(obj[key]);
    }
  }).reduce((a,b) => a+b);
}

const getProjects = (obj) => {
  if (!obj) return [];
  let projects = []
  Object.keys(obj).map(key => {
    let newProject = [];
    if (Array.isArray(obj[key])) {
      newProject = obj[key];
    } else {
      newProject = getProjects(obj[key]);
    }
    projects = [...projects, ...newProject];
  });
  return projects;
}


const Year = (props) => {
  const month = props.month;
  const year = [];


  for (let m = 0; m < 12; m++ ) {
    year.push({
      panoPoints: getPanoPoints(month[m]),
      projects: getProjects(month[m])
    });
  }

  console.log(year);

  const maxPanoOfMonth = year.slice().sort((a, b) => a.panoPoints - b.panoPoints).reverse()[0].panoPoints;

  return (
    <div className="year">
      {year.map(m => {
        return (
          <div data-tip={m.projects.map(p => p.title).join(', ')} className="month" style={{height: (m.panoPoints * 100) / maxPanoOfMonth + '%'}}>{m.panoPoints}</div>
        )
      })}
    </div>
  )
}


export default class MapPage extends React.Component {

  state = {
    projects: false,
  }

  componentDidMount() {
    import("../Projects/projects.js").then((m) => {

      const projects = {};

      m.default.map(p => {
        if (p.date) {
          const date = moment(p.date, 'DD.MM.YYYY');

          const year = date.year();
          const month = date.month();
          const day = date.day();

          if (!projects[year]) projects[year] = {};
          if (!projects[year][month]) projects[year][month] = {};
          if (!projects[year][month][day]) projects[year][month][day] = [];

          projects[year][month][day].push(p);
        }
      });
      this.setState({projects: projects});
      ReactTooltip.rebuild();
    });
  }


  render() {
    const { state } = this;
    const { projects } = state;

    return (
      <Page className="timeline-page" title="Проекты на карте">
        <Section>
          <Title lavel={1}>Timeline</Title>
          {Object.keys(projects).map(year => (
            <div className="lavel">
              {console.log(year, getProjects(projects[year]))}
              <h3>{year}</h3>
              {<Year month={projects[year]} />}
            </div>
          ))}
        </Section>
      </Page>
    )
  }
}
