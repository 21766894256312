import './style.scss';

import React from 'react';
import Page from 'components/Widgets/Page';
import Section from 'Section';
import ErrorPage from "../Error";

export default class ProjectPage extends React.Component {

  state = {
    pending: true,
    project: null
  };

  componentDidMount() {
    import("../Projects/projects.js").then((m) => {
      this.setState({
        pending: false,
        project: m.default.find(p => p.id === parseInt(this.props.match.params.id))
      });
    });
  }

  renderProject(p) {
    return (
      <Section>
        <h1>{p.title}</h1>
        <p>{p.description}</p>
        <iframe className="ProjectPage__iframe" src={p.url} />
        {/*<pre>{JSON.stringify(p, null, 2)}</pre>*/}
      </Section>
    )
  }

  render() {
    const { project } = this.state;

    if (!this.state.pending && (!project || project.display === false)) {
      return <ErrorPage />;
    }

    return (
      <Page
        title="Примеры работ"
        description="Каталог работ"
        keywords={['работы', 'примеры', 'проекты']}
        className="ProjectPage"
      >
        {!this.state.pending ? this.renderProject(project) : null }
      </Page>
    )
  }
}
