import React from 'react';
import Image from 'Image';
import './style.scss';

export default props => (
  <div className="cardboard">
    <Image className="cardboard__box" src={require('assets/images/cardboard.png')} />
    <div className="cardboard__viewport">
      <div className="cardboard__left">{props.children}</div>
      <div className="cardboard__right">{props.children}</div>
    </div>
  </div>
)
