import React from 'react';
import Image from 'Image';
import './style.scss';

export default props => (
  <div className="iphone">
    <Image src={require('assets/images/iphone-xr.png')} />
    <div className="iphone__viewport">
      {props.children}
    </div>
  </div>
)
