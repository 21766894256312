import React from 'react';
import Section from 'Section';
import Button from 'Button';
import Image from 'Image';
import { connect } from 'react-redux';
import calcActions from 'actions/calc.js';

import './style.scss';

export default connect(null, calcActions)(props => (
  <Section className="main-page__calc">
    <div className="main-page__calc__content">
      <h2>Узнайте сколько будет стоить разработка тура</h2>
      <Button onClick={props.openCalcPopup}>Рассчитать стоимость</Button>
    </div>
    <Image src={require('assets/images/calc.jpg')} />
  </Section>
));
