import React from 'react';
import cn from 'classnames';
import './style.scss';
export default props => {
    var value = parseInt(props.children)
      .toLocaleString("ru").replace(/ /g, " ");

    return (
      <span className={cn("price", props.className)}>
        { props.children ? value + ' ₽' : 'Бесплатно' }
      </span>
    )
};
