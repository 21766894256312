export default [
  {
    id: 8,
    date: "01.04.2016",
    url: "https://tour-360.ru/projects/lacoste_v2/?fov=79.3&lat=-0.52&lon=-23.32&id=1",
    image: require('assets/projects/8.jpg'),
    tags: [
      "Лофт",
      "Интерактив",
      "Люди"
    ],
    title: "Lacoste",
    description: "Виртуальный каталог одежды, лофт 1905",
    displaySize: "big",
    panoPoints: 5,
    main: 0,
    rating: 5,
    locations: [
      {
        lat: 55.762268,
        lng: 37.556698
      }
    ],
    color: "766b61"
  },
  {
    id: 76,
    date: "22.11.2017",
    url: "https://tour-360.ru/projects/anderssen/tour/?fov=90&lat=-0.83&lon=-36.01&id=5",
    image: require('assets/projects/76.jpg'),
    title: "ANDERSSEN",
    tags: [
      "Магазин",
      "Шоурум",
      "Выставка"
    ],
    description: "Фабрика диванов",
    displaySize: "normal",
    panoPoints: 9,
    rating: 4,
    locations: [
      {
        lat: 55.751797,
        lng: 37.544053
      }
    ]
  },
  {
    id: 39,
    date: "22.06.2017",
    url: "https://www.google.com/maps/embed?pb=!1m0!4v1499769613468!6m8!1m7!1sF%3A--GI2DJm5ETg%2FWV02iUCEkjI%2FAAAAAAAAqnQ%2FVCPLRanrjzM9Ui7wFW4YtTxD_LPi2dUvgCLIBGAYYCw!2m2!1d55.75330205905351!2d37.57539097219706!3f42.320270094764524!4f0.8783350216170049!5f0.7820865974627469",
    altUrl: "https://goo.gl/maps/g14pyMoeWNv",
    image: require('assets/projects/39.jpg'),
    title: "Shishas Sferum Bar",
    tags: [
      "Бар",
      "Ресторан"
    ],
    description: "Бар на Новом Арбате",
    displaySize: "high",
    panoPoints: 19,
    rating: 5,
    locations: [
      {
        lat: 55.753138,
        lng: 37.57567
      }
    ],
    color: "837053"
  },
  {
    id: 199,
    date: '15.10.2019',
    url: "https://yandex.ru/map-widget/v1/-/CGXkQKp9",
    altUrl: "https://yandex.ru/maps/-/CGXkQKp9",
    image: require('assets/projects/199b.jpg'),
    title: "АвтоБезЗабот",
    tags: [
      "Автосервис"
    ],
    description: "Детейлинг",
    displaySize: "normal",
    panoPoints: 5,
    rating: 5,
    locations: [
      {
        lat: 55.784860,
        lng: 37.571704
      }
    ]
  },
  {
    id: 41,
    date: "18.05.2017",
    url: "https://www.google.com/maps/embed?pb=!1m0!4v1499005873215!6m8!1m7!1sF%3A-WEEZurgA46Q%2FWSbhdXUNn1I%2FAAAAAAAAAD0%2F9DMvSZ9ekx8dB0ZRrRZuFG2cviHxf4FLwCLIB!2m2!1d55.77211354286048!2d37.59636249393225!3f33.82734510415577!4f-0.7429540906365162!5f0.7820865974627469",
    altUrl: "https://goo.gl/maps/zKiCpCeaf782",
    image: require('assets/projects/41.jpg'),
    tags: [
      "Магазин",
      "Оптика"
    ],
    title: "Заказ Линз",
    description: "Салон оптики",
    displaySize: "normal",
    panoPoints: 13,
    rating: 5,
    locations: [
      {
        lat: 55.771875,
        lng: 37.596434
      }
    ],
    color: "b8ad98"
  },
  {
    id: 230,
    date: '19.12.2019',
    url: "https://tour-360.ru/projects/stanzza/2/?id=3&fov=90&lat=0&lon=26.82",
    image: require('assets/projects/230.jpg'),
    title: "4-к квартирра",
    tags: [
      'Квартира',
      'Интерактив',
      '3D'
    ],
    description: "Дизайн проект",
    displaySize: "high",
    panoPoints: 13,
    rating: 5,
    locations: [
      {
        lat: 0,
        lng: 0
      }
    ]
  },
  {
    id: 128,
    date: "06.09.2018",
    url: "https://tour-360.ru/projects/keramin/?fov=90&lat=-0.05&lon=-155.73&id=10",
    image: require('assets/projects/128.jpg'),
    title: "Керамин",
    tags: [
      "Выставка",
      "Шоурум",
      "Магазин"
    ],
    description: "Выставочный павильон плитки",
    displaySize: "normal",
    panoPoints: 24,
    rating: 5,
    locations: [
      {
        lat: 55.751894,
        lng: 37.544826
      }
    ]
  },
  {
    id: 213,
    date: '30.07.2019',
    url: "https://tour-360.ru/projects/sport-marafon/?id=6&fov=90&lat=0.08&lon=290.47",
    image: require('assets/projects/213.jpg'),
    title: "Спорт-Марафон",
    tags: [
      'Пространство',
      'Интеррактивный'
    ],
    description: "Леккторий",
    displaySize: "normal",
    panoPoints: 10,
    rating: 5,
    locations: [
      {
        lat: 55.705226,
        lng: 37.666579
      }
    ]
  },
  {
    id: 229,
    date: '04.02.2020',
    url: "https://tour-360.ru/projects/medical-cg/?id=0&fov=75&lat=-1.21&lon=-58.8",
    image: require('assets/projects/229.jpg'),
    title: "Медикал Консалтинг Групп",
    tags: [
      'Клиника',
      'Школа'
    ],
    description: "Центр обучения для стоматологов",
    displaySize: "big",
    panoPoints: 2,
    rating: 5,
    locations: [
      {
        lat: 55.755977,
        lng: 37.682188
      }
    ]
  },
  {
    id: 132,
    date: "27.04.2018",
    url: "https://www.google.com/maps/embed?pb=!4v1545721189542!6m8!1m7!1sCAoSLEFGMVFpcFAwSGhrZi1YN0ZIVi1tR2E4Q1lYQ3pET0pZTWVnVWpZZjVqeEoz!2m2!1d55.90825561484844!2d37.4143094044947!3f60.654985201236336!4f1.236554122165785!5f0.7820865974627469",
    altUrl: "https://goo.gl/maps/Sxv28QRCTF62",
    image: require('assets/projects/132.jpg'),
    title: "Столовая",
    tags: [
      "Кафе"
    ],
    description: "Химки Бизнес Парк",
    displaySize: "normal",
    panoPoints: 7,
    rating: 5,
    locations: [
      {
        lat: 55.908238,
        lng: 37.414473
      }
    ]
  },
  {
    id: 196,
    date: '12.09.2019',
    url: "https://tour-360.ru/projects/atelier_de_l_artisan/?id=4&fov=100&lat=-0.31&lon=-162.23",
    image: require('assets/projects/196.jpg'),
    title: "ATELIER DE L'ARTISAN",
    tags: [
      "Шоурум",
      "Салон красоты",
      "Клиника",
      "СПА"
    ],
    description: "Ателье в центре Москвы",
    displaySize: "big",
    panoPoints: 17,
    rating: 5,
    locations: [
      {
        lat: 55.758175,
        lng: 37.601439
      }
    ]
  },
  {
    id: 145,
    date: "26.08.2017",
    url: "https://www.google.com/maps/embed?pb=!4v1545722579049!6m8!1m7!1sCAoSK0FGMVFpcE5fWHdONF9FTzloSFNKcXJMci0xOUp1OUthOGpWT2FDWEtTSkk.!2m2!1d55.61108065876824!2d37.60748561471701!3f54.73!4f-1.5600000000000023!5f0.4000000000000002",
    altUrl: "https://goo.gl/maps/DPdVeQFZajr",
    image: require('assets/projects/145.jpg'),
    title: "Raketa Columbus",
    tags: [
      "Фитнес",
      "ТЦ"
    ],
    description: "Сеть фитнес-клубов",
    displaySize: "normal",
    panoPoints: 20,
    rating: 5,
    locations: [
      {
        lat: 55.610886,
        lng: 37.60778
      }
    ]
  },
  {
    id: 58,
    date: '02.08.2018',
    url: "https://www.google.com/maps/embed?pb=!1m0!4v1499012628944!6m8!1m7!1sDkRCDzfgIS4AAAQvxfcKbA!2m2!1d55.80588794031159!2d37.58518050805912!3f128.5543362372656!4f0.14025664406166527!5f0.7820865974627469",
    altUrl: "https://goo.gl/maps/AdtdoFySbZr",
    image: require('assets/projects/58.jpg'),
    title: "MULTIFORM",
    tags: [
      "Лофт",
      "Пространство"
    ],
    description: "Пространство",
    displaySize: "high",
    panoPoints: 10,
    rating: 4,
    locations: [
      {
        lat: 55.805874,
        lng: 37.585241
      }
    ],
    color: "8c9d9d"
  },
  {
    id: 215,
    date: '02.07.2019',
    url: "https://tour-360.ru/projects/hobbygames/?id=4&fov=75&lat=0.33&lon=0",
    image: require('assets/projects/215.jpg'),
    title: "Hobby Games",
    tags: [
      'Магазин',
      'Пространство'
    ],
    description: "Магазин настольных игр‎",
    displaySize: "normal",
    panoPoints: 5,
    rating: 4,
    locations: [
      {
        lat: 55.859993,
        lng: 37.395232
      }
    ]
  },
  {
    id: 228,
    date: '29.01.2020',
    url: "https://www.google.com/maps/embed?pb=!4v1581079372584!6m8!1m7!1sCAoSLEFGMVFpcFB3eVpEUjBILWJSUWxGa0YtOWNTTnpUakZtV2hSREp2WVlwcXgz!2m2!1d55.8192301!2d37.644564!3f355.9187772320455!4f0.322838741673408!5f0.7820865974627469",
    altUrl: "https://goo.gl/maps/SjXqRnBdoyYXZiB98",
    image: require('assets/projects/228.jpg'),
    title: "Ресторан cafe Sicilia",
    tags: [
      "Кафе",
      "Ресторан",
      "Лофт"
    ],
    description: "Кафе",
    displaySize: "normal",
    panoPoints: 3,
    rating: 5,
    locations: [
      {
        lat: 55.819246,
        lng: 37.644462
      }
    ]
  },
];
