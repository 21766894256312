const initialState = {
  popup: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'CALC_POPUP_OPEN': {
          return { ...state, popup: true }
        }
        case 'CALC_POPUP_CLOSE': {
          return { ...state, popup: false }
        }
        default:
          return state
    }
}
