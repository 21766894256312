import React from 'react';
import Page from 'components/Widgets/Page';
import Section from 'Section';
import { asyncComponent } from 'react-async-component';
const ImageSlider = asyncComponent({
  resolve: () => import('ImageSlider')
});

export default (props) => {

  return (
    <Page
      title="Интерьерная съемка"
      description="Cтудия Tour-360 — Интерьерная съемка"
      keywords={['Интерьерная съемка', 'Виртуальный тур', 'заказать']}
    >
      <Section>
          <h1>Интерьерная съемка</h1>
          <p>Помимо создания виртуальных туров, мы также занимаемся интерьерной съемкой.
          3D панорамы отличный способ демонстрации интерьера,
          но у них есть существенный минус — их не напечатаешь на бумаге,
          поэтому мы рекомендуем к виртуальному туру заказать серию интерьерных снимков,
          которые можно будет распечатать, или использовать другими привычными способами.</p>

          <p>Ниже представленны сделаные нами фотографии</p>
          <ImageSlider images={[
            require('assets/photos/44.jpg'),
            require('assets/photos/12.jpg'),
            require('assets/photos/9.jpg'),
            require('assets/photos/23.jpg'),
            require('assets/photos/26.jpg'),
            require('assets/photos/60.jpg'),
            require('assets/photos/41.jpg'),
            require('assets/photos/54.jpg'),
            require('assets/photos/58.jpg'),
            require('assets/photos/39.jpg'),
            require('assets/photos/51.jpg'),
            require('assets/photos/43.jpg')
          ]} />
        </Section>
    </Page>
  )
}
