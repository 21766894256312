import React from 'react';
import './style.scss';
import { Link } from "react-router-dom";
import cn from "classnames";

export default (props) => {
  const className = cn("link", props.className);
  const onClick = props.onClick || (e => {});

  if (props.to) {
    return <Link onClick={onClick} className={className} to={props.to}>{props.children}</Link>
  }

  return <a onClick={onClick} className={className} target={props.target} rel={(props.target === "_blank" ? "noopener noreferrer" : null)} href={props.href}>{props.children}</a>
}
