import React from 'react';
import Section from 'Section';
import Title from 'Title';
import Icon from 'Icon';
import './style.scss';

export default props => {  return (
    <Section className="pluses">
      <Title id="logos" lavel={2}>Наши преимущества</Title>
      <ul>
        <li>
          <Icon name="certificate" />
          <strong>Сертифицированный партнер Google</strong>
          <p>Все наши фотографы прошли сертификацию Google Street View и профессионалы своего дела.</p>
        </li>
        <li>
          <Icon name="diamond_new" />
          <strong>Высокое качество</strong>
          <p>Мы используем самые современные инструменты и технологии, внимательны к деталям и всегда стремимся превзойти себя, именно поэтому наши виртуальные туры такие потрясающие.</p>
        </li>
        <li>
          <Icon name="code" />
          <strong>Собственный плеер панорам.</strong>
          <p>Мы сами разрабатываем ПО, как для создания панорам так и для их просмотра. Собственный плеер панорам позволяет нам решать самые сложные и креативные задачи.</p>
        </li>
        <li>
          <Icon name="experience" />
          <strong>6 лет опыта.</strong>
          <p>У нас за плечами огромный опыт создания коммерческих виртуальных туров различной сложности. За 6 лет мы отсняли более 5 000 панорам на 170 объектах и реализовали 20 масштабных виртуальных экскурсий.</p>
        </li>
        <li>
          <Icon name="cardboard" />
          <strong>Виртуальная реальность</strong>
          <p>Мы разрабатываем туры под очки виртуальной реальности, моделируем 3D объекты для наших туров, интегрируем сложные интерактивные элементы в панорамы.</p>
        </li>
        <li>
          <Icon name="gear" />
          <strong>Поддержка всех популярных устройств.</strong>
          <p>Мы не используем Flash, поэтому наши виртуальные туры можно открыть почти везде.</p>
        </li>
      </ul>
    </Section>
  )
};
