// XHR API

export default class API {
    static objToParams(obj) {
        if (obj) {
            let str = "";
            for (var key in obj) {
                if (str !== "") {
                    str += "&";
                }
                str += key + "=" + encodeURIComponent(obj[key]);
            }
            return "?" + str;
        } else {
            return "";
        }
    }

    static request(q, method, data = false) {
        let host = window.location.origin;
        // let host = 'http://localhost:8087';
        let body = data ? JSON.stringify(data) : null;

        return fetch(host + q, {
            headers: {
                "Content-Type": "application/json",
                "csrf-token": Math.ceil(Date.now()/1000).toString(16)
            },
            method: method,
            credentials: "include",
            body: body
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                return Promise.reject(response);
            }
        })
    }

    static get(q, data) {
        return this.request(q + this.objToParams(data), "GET")
    }
    static post(q, data) {
        return this.request(q, "POST", data)
    }
    static put(q, data) {
        return this.request(q, "PUT", data)
    }
    static delete(q, data) {
        return this.request(q, "DELETE", data)
    }
}
