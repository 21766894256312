import React from 'react';
import Icon from 'Icon'
import cn from 'classnames'
import './style.scss';

const networks = [
  {
    title: 'Facebook',
    icon: 'facebook',
    url: 'https://www.facebook.com/tour360ru/'
  },
  {
    title: 'VKontakte',
    icon: 'vk',
    url: 'https://vk.com/tour360ru'
  },
  {
    title: 'Instagram',
    icon: 'instagram',
    url: 'https://www.instagram.com/tour360ru/'
  },
  {
    title: 'Twitter',
    icon: 'twitter',
    url: 'https://twitter.com/tour360ru'
  },
]

export default (props) => (
  <ul className={cn("social-networks", {"social-networks--light": props.light})}>
    {networks.map((n, key) => (
      <li key={key} className={"social-networks__" + n.icon}>
        <a title={n.title} href={n.url} target="_blank" rel="noopener noreferrer">
          <Icon name={n.icon} />
        </a>
      </li>
    ))}
  </ul>
);
