import React from 'react';
import Body from 'Body';
import Header from '../Header';
import Layout from 'Layout';
import Footer from 'Footer';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import OrderPopup from '../OrderPopup';
import CalcPopup from '../CalcPopup';
import orderActions from 'actions/order.js';
import calcActions from 'actions/calc.js';
import ReactTooltip from 'react-tooltip';
import ReactGA from 'react-ga';
import { YMInitializer } from 'react-yandex-metrika';
import './style.scss';
import Section from "../../UI/Section/index";

const dev = process.env.NODE_ENV !== 'production';

ReactGA.initialize('UA-101925418-1', { debug: dev });

const keywords = [
  'съёмка',
  'виртуальные панорамы',
  'интерактивные фототуры',
  'интерактивная фотосемка',
  'создание виртуальных туров',
  'сферические панорамы',
  'заказать',
  'купить',
  '3d панорамы',
  'HTML5',
  'быстро',
  'качественно'
];


class Page extends React.Component {
  static defaultProps = {
    keywords: [],
    header: true
  }

  componentDidMount() {
    dev && console.log('development mod');
    window.scrollTo(0, 0);
    const url = window.location.pathname + window.location.search;
    ReactGA.pageview(url);



    var t = document.createElement("script");
    t.type = "text/javascript";
    t.async = true;
    t.src = "https://vk.com/js/api/openapi.js?160";

    t.onload = () => {
      const { VK } = window;
      VK.Retargeting.Init("VK-RTRG-111312-53nrK");
      VK.Retargeting.Hit();
    }

    document.head.appendChild(t);
  }

  render () {
    const { props } = this;
    return (
      <Body className={props.className}>
        <ReactTooltip className="tooltip" effect="solid" />
        <YMInitializer accounts={[24409828]} options={{webvisor: true}} version="2" />
        <Helmet>
          <title>{props.title || "Tour-360.ru — Создание виртуальных туров"}</title>
          <meta
            name="description"
            content={props.description || "Профессиональная съемка 3D панорам и создание виртуальных туров 360 градусов"} />
          <meta name="keywords" content={[...keywords, ...props.keywords].join(', ')} />
        </Helmet>
        <Layout>
          { props.header && <Header type={props.accent && "accent"} /> }
          {props.accentTitle && (
            <div className="Header__title">
              <Section>
                <h1>{props.title}</h1>
              </Section>
            </div>
          )}
          <main>
            {this.props.children}
          </main>
          <Footer />
        </Layout>
        {props.calc.popup && <CalcPopup onClose={this.props.closeCalcPopup} /> }
        {props.order.popup && <OrderPopup onClose={this.props.closeOrderPopup} /> }
      </Body>
    )
  }
}

export default connect(
  state => ({
    order: state.order,
    calc: state.calc
  }),
  (dispatch) => ({ ...orderActions(dispatch), ...calcActions(dispatch) })
)(Page);
