import React from 'react';
import Section from 'Section';
import Title from 'Title';
import Icon from 'Icon';
import './style.scss';



// <li>Плавная работа на различных устройствах</li>
// <li>Поддержка очков виртуальной реальности, таких как Oculus Go, Gear VR, Daydream и CardBoard</li>
// <li>Простая и удобная навигация между панорамами</li>
// <li>Интерактивные планы помещений</li>
// <li>Интерфейс на 5ти языках</li>
// <li>Поддержка видео</li>
// <li>Локаничный ситаксис</li>


// Удобные синтаксис (расширяемость)
// Простое подключение
// Поддержка старых браузеров
// Гибкая кастомизация
// Интерактивные сцены
// Попапы, галереи (возможность создания интернет магазина)
// Управление компасом и акселерометром


export default props => {  return (
    <Section className="player-pluses">
      <Title id="logos" lavel={2}>Возможности</Title>
      <ul>
        <li>
          <Icon name="devices" />
          <strong>Поддержка различных устройств</strong>
          <p>Наш плеер плавно работает на всех популярных устройствах</p>
        </li>
        <li>
          <Icon name="lang" />
          <strong>Локализация</strong>
          <p>Интерфейс переведен на 5 популярных языков: Русский, Английский, Немецкий, Китайский, Украинский.</p>
        </li>
        <li>
          <Icon name="code" />
          <strong>Простота в разработке</strong>
          <p>Гибкий и лаконичный синтаксис описания панорам и связей между ними, простая интеграция плеера на сайт</p>
        </li>
        <li>
          <Icon name="art" />
          <strong>Кастомизация</strong>
          <p>Возможность адаптировать интерфейс плеера под ваш фирстиль</p>
        </li>
        <li>
          <Icon name="media" />
          <strong>Видео</strong>
          <p>Возможность интеграции видео фрагментов, например анимированный огонь в камине или девушка на ресепшене</p>
        </li>
        <li>
          <Icon name="cardboard" />
          <strong>Виртуальная реальность</strong>
          <p>Работа в очках виртуальной реальности таких как Oculus Go, Gear VR, Daydream и CardBoard</p>
        </li>
        <li>
          <Icon name="compass" />
          <strong>Навигация</strong>
          <p>Удобная навигация по туру, перемещение при помощи клавиатуры, мыши, касания, наклонов и поворотов</p>
        </li>
        <li>
          <Icon name="cursor" />
          <strong>Интерактивные элементы</strong>
          <p>На нашем плеере виртуальных туров помимо обычных поп-апов и информационных маркеров можно добавить возможность открывания шкафов, выключения света, и перемещения во времени</p>
        </li>
        <li>
          <Icon name="box" />
          <strong>Расширяемость</strong>
          <p>На базе нашего плеера можно создать полноценный интернет-магазин или удобный инструмент по бронированию столиков в ресторане</p>
        </li>
      </ul>
    </Section>
  )
};
