import React from 'react';
import Dialog from 'Dialog';
import Icon from 'Icon';
import Form from './form.js';
import { reset, SubmissionError } from 'redux-form';
import { connect } from 'react-redux'
import api from 'libs/api';
import goal from 'libs/goal';


import './style.scss';

export default connect()(class OrderPopup extends React.Component {
  state = {
    isSubmit: false
  }

  render() {
    const { props, state } = this;
    return (

      <Dialog className="order-popup" onClose={props.onClose} open={props.open}>
        <div onClick={props.onClose} className="order-popup__close-button">
          <Icon name="cross" />
        </div>
        { state.isSubmit ? (
          <div>
            <h2>Спасибо!</h2>
            <p>Заявка успешно отправлена. В скором времени с вами свяжется наш специалист.</p>
          </div>
        ) : (
          <div>
            <h2><a href="tel:+74950158360">+7 (495) 015 83 60</a></h2>
            <p>
              Позвоните нам для уточнения деталей вашего проекта.
              Мы сориентируем вас по срокам и ценам.
              Или заполните форму ниже, и мы сами с вами свяжемся.
            </p>
            <Form className="order-popup__form" onSubmit={this.handleShubmit} />
          </div>
        )}
      </Dialog>
    )
  }

  handleShubmit = (data) => {
    return api.post('/api/order', data).then(() => {
      this.setState({isSubmit: true});
      this.props.dispatch(reset('order'));
      goal('submit-form');
    }).catch(() => {
      throw new SubmissionError({ _error: 'Ошибка отправки формы!' })
    })
  }
});
