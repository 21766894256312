import React, { Suspense } from 'react';
import Section from 'Section';
import Page from 'components/Widgets/Page';
import Tag from 'Tag';
import Gallery from 'components/Widgets/Gallery';
import ProjectPopup from 'ProjectPopup';
import { forceCheck } from 'react-lazyload';
import queryString from 'query-string';
// import moment from 'moment';


export default class Projects extends React.Component {

  state = {
    projects: [],
    currentProjects: [],
    currentTags: [],
    tags: {},
    stats: {},
    popup: {
      visible: false
    },
    hideTags: true,
  };

  componentDidUpdate(a, b) {
    window.location.hash = this.state.currentTags.join(',');
    forceCheck();
  }


  componentDidMount() {
    import("./projects.js").then((m) => {
      const stats = {
        projects: 0,
        panorams: 0
      };

      const tags = {};

      const query = queryString.parse(window.location.search).q;

      const tagsUrl = decodeURIComponent(window.location.hash.substr(1))
        .split(',')
        .filter(t => t !== "");

      const currentTags = tagsUrl.length ? tagsUrl : [];

      const projects = m.default.map((p, key) => {
        stats.projects++;
        stats.panorams += p.panoPoints;

        p.tags && p.tags.map(t => {
          if (tags[t]) {
            tags[t].amound++;
          } else if (p.display !== false) {
            tags[t] = {
              checked: !!~tagsUrl.indexOf(t),
              amound: 1
            }
          }
          return true;
        });

        return {
          ...p,
          // id: key,
          locations: p.locations.map((a, key) => ({
            ...a,
            id: key
          }))
        };
      })
      // .sort((a, b) => {
      //   return (moment(a.date, 'DD.MM.YYYY').unix() || 0) - (moment(b.date, 'DD.MM.YYYY').unix() || 0)
      // });


      this.setState({
        projects,
        query,
        currentTags,
        currentProjects: this.filter(projects, currentTags, query),
        stats,
        tags,
        hideTags: currentTags.length < 1
      });

    })
  }

  handleSelectTag = (tag) => () => {

    let { tags, projects } = this.state;

    tags = {
      ...tags,
      [tag]: {
        ...tags[tag],
        checked: (!tags[tag].checked)
      }
    };

    const currentTags = Object.keys(tags).filter(k => tags[k].checked);

    this.setState({
      tags,
      currentTags,
      query: false,
      currentProjects: this.filter(
        projects,
        currentTags
      )
    });
  }

  filter (projects, currentTags = this.state.currentTags, query = false) {
    return projects.filter(project => {


      if (query) {
        if(!project.title.toLowerCase().includes(query.toLowerCase())) {
          return false;
        }
      }

      if (currentTags.length === 0) {
        if (project.display === false) {
          return false;
        } return true;
      }

      if (project.tags && project.tags.filter(tag => {
        return currentTags.filter(cTag => {
          return tag === cTag;
        }).length;
      }).length) return true;

      return false;
    });
  }


  getProject(id, bios = 0) {
    let key = 0;
    this.state.currentProjects.find((p, n) => {
      if(p.id === id) {
        key = n;
        return this.state.currentProjects[n];
      } else return false;
    });
    return this.state.currentProjects[key + bios];
  }

  render () {
    let { state, state: { tags, stats, popup } } = this;
    tags = Object.keys(state.tags)
      .sort((a, b) => (tags[b].amound - tags[a].amound))
      .splice(0, (state.hideTags ? 14 : Object.keys(tags).length));

    return (
      <Page
        title="Наши работы"
        description="Каталог работ"
        keywords={['работы', 'примеры', 'проекты']}
      >
        {
            popup.visible &&
            <ProjectPopup
            {...popup}
            onClose={this.handlePopupClose}
            prew={this.handleProjectSelect(-1)}
            next={this.handleProjectSelect(1)}
          />
        }
        <Section>
          <center>
            <h1>Наши работы</h1>
          </center>
          <div>
            {
              tags.map((tag) => (
                <Tag key={tag} title={state.tags[tag].amound} onClick={this.handleSelectTag(tag)} active={state.tags[tag].checked}>{tag}</Tag>
              ))
            }
            { state.hideTags && <Tag onClick={() => this.setState({hideTags: false})} title="Показать все теги">...</Tag> }
          </div>
          <Suspense fallback={"Загрузка..."}>
            <Gallery limit={48} onSelectTag={t => { this.handleSelectTag(t)(); }} projects={state.currentProjects} simple={state.currentTags.length || state.query} />
          </Suspense>
          <p>Снято {stats.panorams} панорам на {stats.projects} объектах</p>
        </Section>
      </Page>
    )
  }
}
