import React from 'react';
import Section from 'Section';
import Title from 'Title';
import Image from 'Image';
import './style.scss';

export default props => {
  const logos = [
    { name: "Lacoste", image: require('assets/images/logos/logo_01.jpg') },
    { name: "Coral travel", image: require('assets/images/logos/logo_02.jpg') },
    // { name: "Счастье", image: require('assets/images/logos/logo_03.jpg') },
    // { name: "Советская аптека", image: require('assets/images/logos/logo_04.jpg') },
    { name: "ЦСКА", image: require('assets/images/logos/logo_05.jpg') },
    { name: "Интан", image: require('assets/images/logos/logo_06.jpg') },
    // { name: "Медвед", image: require('assets/images/logos/logo_07.jpg') },
    { name: "ММЦ", image: require('assets/images/logos/logo_18.jpg') },
    // { name: "Магистрат", image: require('assets/images/logos/logo_08.jpg') },
    // { name: "КухниПарк", image: require('assets/images/logos/logo_09.jpg') },
    { name: "СтолПлит", image: require('assets/images/logos/logo_10.jpg') },
    { name: "Сбербанк Лизинг", image: require('assets/images/logos/logo_19.jpg') },
    // { name: "Бункер-42", image: require('assets/images/logos/logo_11.jpg') },
    // { name: "Горница узорница", image: require('assets/images/logos/logo_12.jpg') },
    { name: "Avilon", image: require('assets/images/logos/logo_13.jpg') },
    { name: "Kometa Fit", image: require('assets/images/logos/logo_14.jpg') },
    // { name: "MOSS", image: require('assets/images/logos/logo_15.jpg') },
    { name: "Замания", image: require('assets/images/logos/logo_16.jpg') },
    { name: "Клевер", image: require('assets/images/logos/logo_17.jpg') },
    { name: "Донстрой", image: require('assets/images/logos/logo_20.jpg') },
    // { name: "Markess", image: require('assets/images/logos/logo_21.jpg') },
    // { name: "Инфофлот", image: require('assets/images/logos/logo_22.jpg') },
  ]

  return (
    <Section className="steps">
      <Title id="logos" lavel={2}>Нам доверяют</Title>
      <div className="logos">
        {logos.map(({name, image}, key) => (
          <Image key={key} title={name} alt={name} src={image} />
        ))}
      </div>

    </Section>
  )
};
