import React from 'react';
import cn from 'classnames';
import Price from 'Price';
import './style.scss';

export default props => (
  <div className="tabs">
    {props.tabs.map(tab => (
      <div
        data-tip={tab.title}
        {...({id: tab.id || false})}
        onClick={(e) => props.onChange(tab.value, e)}
        className={cn("tabs__tab", { "tabs__tab--active": tab.value === props.value })}
      >
      { tab.label }
      { tab.price && (<Price className="tabs__tab__price">{tab.price}</Price>) }
      </div>
    ))}
  </div>
)
