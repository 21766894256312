import React from 'react';
import Body from 'Body';
import Section from 'Section';
import Link from 'Link';
import { Helmet } from "react-helmet";

export default () => (
  <Body>
    <Helmet>
      <title>Ошибка 404. Такой страницы не существует</title>
      <meta name="description" content="Такой страницы не существует" />
    </Helmet>
    <Section>
      <h1>Ошибка 404</h1>
      <p>Такой страницы не существует, <Link to="/">Перейти на главную</Link></p>
    </Section>
  </Body>
)
