import React from 'react';
import Page from 'components/Widgets/Page';
import Section from 'Section';
import Button from 'Button';
import Title from 'Title';
import Price from 'Price';
import Icon from 'Icon';
import Link from 'Link';
import ReactTooltip from 'react-tooltip';

import Tariffs from './Tariffs';

import orderActions from 'actions/order.js';
import { connect } from 'react-redux'

import './style.scss';

const Check = () => (
  <Icon className="check" name="check_mark" />
)

const Info = props => (
  <div data-for={props.for} data-tip={props.text} className="info"><Icon name="info" /></div>
)

export default connect(null, orderActions)((props) => (
  <Page
    className="price-page"
    title="Стоимость создания виртуального тура"
    description="Расчитать стоимость создания виртуального тура"
    keywords={['Стоимость', 'Цены', 'Тарифы', 'Услуги']}
  >
    <Section>
      <h1>Стоимость</h1>
      <p>Стоимость виртуального тура зависит от его размера. Чем больше площадь, тем больше нужно снять панорам. Стоимость тура рассчитывается из количества панорам, как правило на каждые 10 м² нужно снять одну панораму. Но это цифра различается на разных типах объекта.</p>
      <Title lavel={2}>Тарифы</Title>
      <p><b>Цена указана за съемку одной панорамы.</b></p>
      <Tariffs />

      <h3>Подробное стравнение тарифов</h3>

      <ReactTooltip className="tooltip tooltip--image" id="nadir" effect="solid">
        <img alt="Надир" src={require('assets/images/nadir.gif')} />
      </ReactTooltip>
      <table className="table">
        <tr>
          <th></th>
          <th>Лайт<br /><Price>1000</Price></th>
          <th>Стандарт<br /><Price>1500</Price></th>
          <th>Премиум<br /><Price>3000</Price></th>
          <th>Люкс<br /><Price>5000</Price></th>
        </tr>
        <tr>
          <td>Выезд фотографа</td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Съемка в HDR</td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Съемка в ультра высоком разрешении 512 Мп</td>
          <td></td>
          <td></td>
          <td></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Сшифка фотографий в панораму</td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Ручная сшифка <Info text="Если в кадре есть движущиеся объекты (машины или люди), либо пространство сильно ограниченно (салон автомобиля или узкий коридор), «сшить» панораму можно только в ручном режиме." /></td>
          <td></td>
          <td></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Размещение тура на нашем хостинге</td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Ретушь надира (штатива на полу) <Info text="nadir" for="nadir" /></td>
          <td></td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Ретушь бликов</td>
          <td></td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Ретушь отражений</td>
          <td></td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Ретушь нежелательных объектов в кадре <Info text="людей, пятен на потолке, проводов, камер видеонаблюдения, и т. п." /></td>
          <td></td>
          <td></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Глубокая Hi-End ретушь</td>
          <td></td>
          <td></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Ручная цветокоррекция</td>
          <td></td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        <tr>
          <td>Объеденение панорам в виртуальный тур <Info text="Создания связий (переходов) между панорамами. В результате тур полностью готов к публикации на вашем сайте." /></td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
          <td><Check /></td>
        </tr>
        {/*<tr>*/}
        {/*  <td>Публикация панорам на Google картах</td>*/}
        {/*  <td><Check /></td>*/}
        {/*  <td><Check /></td>*/}
        {/*  <td><Check /></td>*/}
        {/*  <td><Check /></td>*/}
        {/*</tr>*/}
        <tr>
          <td>Размер изображения</td>
          <td>8000×4000</td>
          <td>8000×4000</td>
          <td>8000×4000</td>
          <td>16000×8000</td>
        </tr>
        <tr className="table__footer">
          <td></td>
          <td><Button onClick={props.openOrderPopup} small>Заказать</Button></td>
          <td><Button onClick={props.openOrderPopup} small>Заказать</Button></td>
          <td><Button onClick={props.openOrderPopup} small>Заказать</Button></td>
          <td><Button onClick={props.openOrderPopup} small>Заказать</Button></td>
        </tr>
      </table>


      <div className="price-page__services-list">
        <ul>
          <li>
            <strong>Публикация виртуального тура на Каротах Google — <Price>1000</Price></strong>
            <p>Отснятые панорамы мы загружаем в Google street view, проставляем переходы между ними. Выши клиенты будут видеть панорамы заведения в поиске Google</p>
          </li>
          <li>
            <strong>Публикация виртуального тура на Яндекс Картах — <Price>1000</Price></strong>
            <p>Отснятые панорамы мы разместим на Яндекс Картах, проставляем переходы между ними.</p>
          </li>
          <li>
            <strong>Съемка интерьера до 20 фотографий — <Price>3000</Price></strong>
            <p>Для публикации там где нельзя разместить виртуальный тур (Яндекс.Справочник, 2gis, Avito, Циан) или для печати.</p>
          </li>
          <li>
            <strong>Добавление в виртуальный тур<br />интерактивной карты объекта — <Price>3000</Price></strong>
            <p>Если у вас сложная планировка, то мы рекомендуем сделать интерактивную карту с отображением
            актуального угла обзора, она позволит не заблудиться пользователи и пройти все
            интересующие его помещения. <Link target="_blank" href="https://tour-360.ru/projects/allergyfree/?fov=75&lat=-1.3&lon=11.2&id=27">Пример карты клиники</Link></p>
          </li>
          <li>
            <strong>Добавление поп-апов и подсказок — <Price>3000</Price></strong>
            <p>Чтобы более подробно рассказать о вашем товаре или услуге,
            рекомендуем добавить в виртуальный тур подсказки. <Link target="_blank" href="https://tour-360.ru/projects/lacoste_v2/?fov=79.3&lat=-0.52&lon=-23.32&id=1">Пример подсказок на проекте Lacoste</Link></p>
          </li>
        </ul>
        <ul>
          <li>
            <strong>Публикация виртуального тура в ВКонтакте — <Price>2000</Price></strong>
            <p>На основе виртуального тура мы создадим приложение ВКонтакте, оно будет отображаться на видном месте в вашей группе.</p>
          </li>
          <li>
            <strong>Публикация виртуального тура в Facebook — <Price>2000</Price></strong>
            <p>Мы встроим виртуальный тур в ваше сообщество Facebook</p>
          </li>
          <li>
            <strong>Публикация виртуального тура в Instagram — <Price>1500</Price></strong>
            <p>Подготовка фото и видеоматериала для публикации постов и сторисов в инстаграме.</p>
          </li>
          <li>
            <strong>Виртуальный тур в формате 360° 8K видео на YouTube — <Price>3000</Price></strong>
            <p>Чтобы виртуальный тур увидели еще больше людей, мы сделаем из него 360° 8K видео для YouTube. <Link target="_blank" href="https://www.youtube.com/watch?v=ErsYTVViBhY">пример видео</Link></p>
          </li>
          <li>
            <strong>Добавление видео в тур — <Price>500</Price>/шт.</strong>
            <p>В виртуальный тур мы можем добавить видео, это может быть анимация камина или рекламный ролик в телевизоре вашего интерьера.</p>
          </li>
          <li>
            <strong>Дополнительный выезд фотографа — <Price>1500</Price></strong>
            <p>Если потребуется еще один выезд фотографа на съемку, то он оплачивается отдельно.</p>
          </li>
        </ul>
      </div>
    </Section>
  </Page>
));
