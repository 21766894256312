import React from 'react'
import './style.scss';
import Icon from 'Icon';
import Image from 'Image';
import Button from 'Button';
import cn from 'classnames';

export default class ProjectCard extends React.Component {


  handleSelectTag = tag => e => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.onSelectTag) {
      this.props.onSelectTag(tag);
    } else {
      document.location.href = "/projects#" + tag;
    }
  }

  render () {
    const { id, title, tags, url, altUrl, description, locations, panoPoints, image } = this.props;
    if (title) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`/project/${id}`} onClick={this.props.onClick} className={cn("project-card", this.props.className)}>
          <figure>
            {/* this.props.rating && <span className="project-card__rating">{this.props.rating}</span> */}
            <Image className="project-card__image" alt={title} src={image} />
            <div className="project-card__tags">
              {tags && tags.map((tag, key) => (
                <div className="link" key={key} onClick={this.handleSelectTag(tag).bind(this)} to={"/projects#" + tag}>#{tag}</div>
              ))}
            </div>
            <figcaption>
              <b className="project-card__title">{title}</b>
              <div className="project-card__line">
                <p className="project-card__description">{description}</p>
                <Button className="project-card__button" small><Icon name="play" /> Смотреть</Button>
                <div className="project-card__counters">
                  { url.includes('google.com') && <span title="Есть на картах Google Street View"><Icon name="streetview" /></span> }
                  { url.includes('yandex.ru') && <span title="Есть на Яндекс картах"><Icon name="yandexmap" /></span> }
                  { (locations && locations.length > 1) && <span title={`${locations.length} объектов на карте`}>{locations.length} <Icon name="location" /></span> }
                  { panoPoints && <span title={`Снято ${panoPoints} панорам`}>{panoPoints} <Icon name="pano" /></span> }
                </div>
              </div>
            </figcaption>
          </figure>
        </a>
      )
    } else {
      return (
        <figure className="project-card project-card--placeholder" />
      );
    }
  }
}
