import React from 'react';
import Button from 'Button';
import Price from 'Price';
import orderActions from 'actions/order.js';
import { connect } from 'react-redux';
import cn from 'classnames';
import './style.scss';

import tariffs from 'data/tariffs.js';


export default connect(null, orderActions)((props) => (
  <div className="tariffs">
    {tariffs.map((tariff, key) => (
      <div className={cn("tariff", {"tariff--hit": tariff.hit})}>
        <strong>{tariff.name}</strong>
        <div className="tariff__price">
          <Price>{tariff.price}</Price> / панорама
        </div>
        <p>{tariff.description}</p>
        <Button onClick={props.openOrderPopup}>Заказать</Button>
      </div>
    ))}
  </div>
));
