export default [
  {
    id: 'lite',
    name: 'Лайт',
    image: require('assets/tariffs/light.jpg'),
    price: 1000,
    description: `Автоматическая сшивка.
                  Без ретуши (виден штатив на полу
                  и в отражении зеркал). Базовая цветокоррекция.
                  Без правок. Возможны пересветы в окнах.`
  },
  {
    id: 'standart',
    name: 'Стандарт',
    price: 1500,
    hit: true,
    image: require('assets/tariffs/standart.jpg'),
    description: `Автоматическая сшивка.
                  Убираем блики, штатив на полу и в отражениях.
                  Ручная цветокоррекция.`
  },
  {
    id: 'premium',
    name: 'Премиум',
    image: require('assets/tariffs/premium.jpg'),
    price: 3000,
    description: `Ручная сшивка.
                  Убираем блики, нежелательные объекты в кадре, штатив на полу и в отражениях.
                  Ручная цветокоррекция, глубокая Hi-End ретушь.`
  },
  {
    id: 'premiumPlus',
    name: 'Люкс',
    image: require('assets/tariffs/premium.jpg'),
    price: 5000,
    description: `Съемка в ультра высоком разрешении. Ручная сшивка.
                  Убираем блики, нежелательные объекты в кадре, штатив на полу и в отражениях.
                  Ручная цветокоррекция, глубокая Hi-End ретушь.`
  }
]
