import React from 'react'
import './style.scss';
import Icon from 'Icon';
import cn from 'classnames';
import Link from 'Link';

const Logo = (props) => {
  return (
    <span className={cn("logo", {
      'logo--accent': props.accent,
      'logo--small': props.small,
    })}>
      <div className="logo__wrapper">
        <Icon name="logo" />
      </div>
      {props.children &&
        <span className="logo__title">{props.children}</span>
      }
    </span>
  );
}

export default (props) => {
  if (props.link) {
    return <Link className="logo__link" to="/"><Logo {...props} /></Link>
  }

  return <Logo {...props} />
}
