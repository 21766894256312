import React from 'react';
import Page from 'components/Widgets/Page';
import { GoogleMap, Marker } from 'GoogleMap';
import ProjectPopup from 'ProjectPopup';
import Link from 'Link';
import Tabs from 'Tabs';
import goal from 'libs/goal.js';
import Section from 'Section';
import Title from 'Title';

import './style.scss';

export default class MapPage extends React.Component {

  state = {
    projects: [],
    popup: {
      visible: false
    },
    citySelect: 0,
    cityes: [
      {
        value: 0,
        label: 'Москва',
        center: { lat: 55.754383, lng: 37.622277 },
        zoom: 10
      },
      {
        value: 1,
        label: 'Санкт-Петербург',
        center: { lat: 59.937457, lng: 30.284234 },
        zoom: 10
      }
    ]
  }

  componentDidMount() {
    import("../Projects/projects.js").then((m) => {
      const projects = m.default.slice().sort((a, b) => {
        return (a.title > b.title) ? 1 : -1;
      });
      this.setState({projects: projects});
    });
  }

  handlePopupClose = () => {
    this.setState({popup: {visible: false}});
  }

  handleProjectClick = (id, pKey, lKey) => (e) => {
    goal('popup-projects', id);

    const project = this.state.projects[pKey];
    const location = project.locations[lKey];
    if (lKey !== undefined && project.locations.length > 1) {
      project.url = location.url;
    }

    if (window.innerWidth > 600) {
      this.setState({popup: { ...project, id: pKey, visible: true }});
    } else {
      window.open(location.altUrl ||  project.altUrl || location.url || project.url);
    }
  }

  render() {
    const { state } = this;
    const { projects } = state;

    return (
      <Page className="map-page" title="Проекты на карте">
        {
          this.state.popup.visible ?
            <ProjectPopup
              { ...state.popup }
              onClose={this.handlePopupClose}
            /> : false
        }
        <Section>
          <Title lavel={1}>Проекты на карте</Title>
          <Tabs value={this.state.citySelect} tabs={this.state.cityes} onChange={(value) => { this.setState({citySelect: value})}} />
        </Section>

        <div className="map-page__map">
          {<GoogleMap zoom={state.cityes[state.citySelect].zoom} center={state.cityes[state.citySelect].center}>
            {projects.map((project, pKey) => {
              return project.locations && project.locations.map((l, lKey) => {
                return (
                  <Marker title={project.title} key={pKey + ' ' + lKey} onClick={this.handleProjectClick(project.id, pKey, lKey)} position={{ lat: l.lat, lng: l.lng }} />
                )
              })
            })}
          </GoogleMap>}
        </div>

        <Section>
          <Title lavel={2}>Проекты списком</Title>
          <div className="map-page__links">
            {projects.map((project, key) => {
                return (
                  <Link key={key} onClick={this.handleProjectClick(project.id, key)}>{project.title}</Link>
                )
            })}
          </div>
        </Section>
      </Page>
    )
  }
}
