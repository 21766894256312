import React from 'react';
import Image from 'Image';
import './style.scss';

export default class MapPage extends React.Component {

  state = {
    width: 'initial'
  }

  render () {
    const { state } = this;
    return (
      <div ref="content" onMouseMove={this.handleMove} className="comparison-slider">
        <div style={{width: state.width}} className="comparison-slider__before">
          <Image src={require("assets/images/hdr2.jpg")} />
        </div>
        <div className="comparison-slider__separator" style={{left: state.width}} />
        <div className="comparison-slider__after">
          <Image src={require("assets/images/hdr.jpg")} />
        </div>
      </div>
    )
  }

  handleMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    this.setState({width: x});
  }

  // componentDidMount() {
  //   this.setSize();
  // }
}
