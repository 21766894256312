import React from 'react';
import Tabs from 'Tabs';

export default class FaqPage extends React.Component {
  state = {
    pano: 'bad',
  }
  render () {
    return (
      <div>
        <Tabs value={this.state.pano} tabs={[
          { value: "bad", id: 'change-bad', label: "Плохая" },
          { value: "good", id: 'change-good', label: "Хорошая" }
        ]} onChange={value => this.setState({pano: value})} />

        <iframe title="Плохая и хорошая панорама" className="bad-good-pano" src="/projects/bad_good/?fov=81.3&lat=-9.37&lon=252.33&id=bad" allowFullScreen />
      </div>
    )
  }
}
