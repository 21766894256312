import React from 'react';
import Page from 'components/Widgets/Page';
import Section from 'Section';
import ComparisonSlider from 'ComparisonSlider';
import Link from 'Link';
import Code from 'Code';
import Title from 'Title';
import BadGoodPano from './BadGoodPano';
import './style.scss';

export default props => (
  <Page
    title="Вопросы и ответы"
    description="Часто задоваемые вопросы на тему создания виртуального тура"
    keywords={['HDR', 'Подготовка', 'Виртуальный тур', 'Этапы', 'Обработка']}
  >
    <Section>
        <h1>Вопросы и ответы</h1>
        <p>Чтобы оперативно отвечать на ваши вопросы, мы собрали все часто<br />задаваемые вопросы на одной странице.</p>
        <ul>
          <li><Link href="#hdr">Что такое HDR</Link></li>
          <li><Link href="#shooting">Как подготовить объект съемки?</Link></li>
          <li><Link href="#share">Как разместить виртуальный тур на сайте?</Link></li>
          <li><Link href="#interior-photography">Делаете ли вы интерьерную съемку?</Link></li>
          {/*<li><Link href="#aaa">Этапы обработки панорам</Link></li>*/}
          <li><Link href="#kit">Какое оборудование вы используете?</Link></li>
        </ul>

        <Title lavel={2} id="hdr">Что такое HDR?</Title>
        <p>Мы снимает все панорамы исключительно в HDR. HDR — High Dynamic Range
        (широкий динамический диапазон)</p>
        <p>Цифровая камера, в отличии от человеческого глаза имеет более узкий
        динамический диапазон, она не способна передать очень темные и очень светлые
        участки сцены. На изображении ниже приведено сравнение снимков с применением
        технологии HDR и без.</p>

        <p>
          <ComparisonSlider src={require("assets/images/hdr.jpg")} />
        </p>

{/*          <p>Обратите внимание, что на снимке слева, снятым на цифровую камеру,
        небо слишком светлое, в нем не видно деталей облаков, а трава и забор слишком темные,
        в них так же пропадают детали. А на изображении справа, условно демонстрирующий
        человеческое зрение, одинаково хорошо видно детали в облаках и в темных местах.</p>
*/}
        <p>Обратите внимание, что на снимке слева,
        неоновые вывески пересвечены, а область под диваном слишком темная, из-за избыточного контраста пропадают важные детали интерьера. А на изображении справа, снятой с применением технологии HDR этих проблем нет.</p>

        <Title lavel={2} id="shooting">Как подготовить объект к съемке?</Title>
        <p>Несмотря на то, что виртуальный тур должен переносить зрителя в атмосферу
        максимально приближенной к действительной, стоит учесть некоторые нюансы.
        Во время съемки все должно быть точно также как в любой другой день, однако
        беспорядок не оставит у зрителя виртуального тура хорошего впечатления о компании.</p>

        <p>Время съемки стоит выбрать исходя из посещаемости объекта, желательно
        чтобы на момент съемки не было посетителей или их было минимальное количество,
        а на улице было достаточно светло.</p>

        <ul>
          <li>Рекомендуется провести уборку перед съемкой виртуальных туров,
          грязь и пыль на зеркальных поверхностях может стать заметнее на виртуальном туре.</li>
          <li>Убедитесь, что все источники искусственного освещения исправны</li>
          <li>Если в помещении, где проводится съемка, много дверей, их необходимо
          зафиксировать в открытом состоянии, чтобы сократить время съемки.</li>
        </ul>

        <p>Если вы не хотите, чтобы что-то не попадало в кадр, необходимо предупредить фотографа перед началом съемки.</p>

        <Title lavel={2} id="share">Как разместить виртуальный тур на сайте?</Title>
        <Title lavel={3} id="share-html5-player">HTML5 Плеер панорам</Title>
        <p>Наши виртуальные туры очень просто встраиваются на сайт, для этого необходимо:</p>
        <ol>
          <li>Перейти на страницу с виртуальным туром</li>
          <li>Щелкнуть правой кнопкой мыши в любом месте</li>
          <li>В появившемся контекстном меню выбрать пункт «Получить код для вставки».</li>
        </ol>
        <p>В буфер обмена будет скопирован HTML-код, который будет выглядеть примерно так:</p>

        <video width="794" autoplay="autoplay" loop muted>
          <source src={require('assets/media/copy-code.webm')} type="video/webm" />
          <source src={require('assets/media/copy-code.mp4')} type="video/mp4" />
        </video>

        <Code lang="html">
          {`<iframe src="//tour-360.ru/projects/promo/" width="640" height="480" frameborder="no" scrolling="no" allowfullscreen></iframe>`}
        </Code>
        <p>Этот код нужно будет вставить в код сайта, в то место, где вы хотите видеть
        виртуальный тур. Важно отметить, что виртуальный тур будет начинается именно
        с того положения, с которого вы скопировали код. Изменяя параметры width и height
        можно управлять шириной и высотой отображения виртуального тура.</p>

        <Title lavel={3} id="share-google-maps">Google maps</Title>

        <p>Для того чтобы разместить виртуальный тур у себя на сайте, необходимо:</p>

        <ol>
          <li>Перейти на страницу с панорамой и кликнуть на 3 точки в верхнем левом углу.</li>
          <li>Далее нажать на пункт "Поделиться с друзьями или получить код для вставки".</li>
          <li>Перейти на вкладку "HTML".</li>
          <li>Выбрать подходящий размер и скопировать код на ваш сайт.</li>
        </ol>

        <video width="795" autoplay="autoplay" loop muted>
          <source src={require('assets/media/copy-code-google.webm')} type="video/webm" />
          <source src={require('assets/media/copy-code-google.mp4')} type="video/mp4" />
        </video>

        <Title lavel={2} id="bad-good-pano">Отличие хорошей панорамы от плохой</Title>
        <p>Наши панорамы отличаются от большинства других панорам за счет соблюдения технологий съемки и ручной обработки. Мы с вниманием относимся к проблемам освещения, убираем отражения камеры, ретушируем штатив на полу. Ниже продемонстрированы различия между условно плохой панорамой, которую без проблем можно встретить в интернете и панорамой снятой по нашей технологии.</p>
        <BadGoodPano />

        <Title lavel={2} id="interior-photography">Делаете ли вы интерьерную съемку?</Title>
        <p>Да, помимо съемки виртуальных туров мы занимаемся интерьерной фотографией. Подробную информацию об этом можно найти на странице <Link href="/interior-photography">интерьерной съемки</Link>.</p>

        <Title lavel={2} id="kit">Какое оборудование вы используете?</Title>
        <p>Для съемки виртуальных туров обычно мы используем:</p>
        <ul>
          <li>Камера Canon EOS 5D Mark III</li>
          <li>Объектив Sigma AF 8mm f/3.5 EX DG</li>
          <li>Панорамная голова 360 Precision Atome</li>
          <li>Штатив Benro a1970t</li>
        </ul>
        <p>В некоторых случаях мы используем:</p>
        <ul>
          <li>Камера Canon EOS 550D</li>
          <li>Объектив Canon EF-S 18-55mm f/3.5-5.6 IS</li>
          <li>Объектив Samyang 8mm f/3.5 fisheye</li>
          <li>Панорамная голова Fotomate Pro Panoramic Kit</li>
          <li>Панорамная голова NP Orbita</li>
          <li>Объектив Canon EF 17-40mm f/4L USM</li>
          <li>Объектив Canon 50mm 1.8 II</li>
          <li>Объектив EF 75-300mm f/4-5.6 III</li>
          <li>Радиосинхронизатор Yongnuo RF 603c</li>
          <li>Вспышка YongNuo Speedlite YN685</li>
        </ul>
      </Section>
  </Page>
);
