import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import MainPage from 'components/Pages/Main';
import ProjectsPage from 'components/Pages/Projects';
import ProjectPage from 'components/Pages/Project';
import MapPage from 'components/Pages/Map';
import TimeLinePage from 'components/Pages/TimeLine';
import RequisitesPage from 'components/Pages/Requisites';
import ContactsPage from 'components/Pages/Contacts';
import PricePage from 'components/Pages/Prices';
import AgreementPage from 'components/Pages/Agreement';
import ErrorPage from 'components/Pages/Error';
import FaqPage from 'components/Pages/Faq';
import Player from 'components/Pages/Player';
import Empty from 'components/Pages/Empty';
import Blog from 'components/Pages/Blog';
import InteriorPhotography from 'components/Pages/InteriorPhotography';
import { Provider } from "react-redux";
import store from './redux/createStore.js';
import queryString from 'query-string';

class App extends Component {
  componentDidMount() {
    const { calc } = queryString.parse(window.location.search);
    console.log({ calc });
    calc !== undefined && store.dispatch({type: 'CALC_POPUP_OPEN'});
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={MainPage} />
            <Route path="/project/:id" component={ProjectPage} />
            <Route path="/projects" component={ProjectsPage} />
            <Route path="/map" component={MapPage} />
            <Route path="/timeline" component={TimeLinePage} />
            <Route path="/contacts" component={ContactsPage} />
            <Route path="/requisites" component={RequisitesPage} />
            <Route path="/prices" component={PricePage} />
            <Route path="/faq" component={FaqPage} />
            <Route path="/agreement" component={AgreementPage} />
            <Route path="/interior-photography" component={InteriorPhotography} />
            <Route path="/player" component={Player} />
            <Route path="/empty" component={Empty} />
            <Route path="/blog" component={Blog} />
            <Route component={ErrorPage} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
