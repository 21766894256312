import React from 'react';
import Highlight from 'react-highlight.js';
import './style.scss';

export default (props) => {
  return (
    <Highlight language={props.lang}>{props.children}</Highlight>
  )
  // return (
  //   <pre className="code">{props.children}</pre>
  // );
}
