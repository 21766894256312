import React, { useState, useCallback, useEffect, useRef } from 'react'
import './style.scss';
import cn from 'classnames';
import LazyLoad from 'react-lazyload';

export default ({ className, title, style, alt, src }) => {

  const { images } = src;
  const ref = useRef(null);

  const [image, setImage] = useState(null);
  const [loaded, setLoaded] = useState(null);

  useEffect(() => {
    const ratio = window.devicePixelRatio;
    const width = ref.current.clientWidth * ratio;
    const height = ref.current.clientHeight * ratio;


    const optimalImage = images.find((img, i) => {
      console.log('find', i, width, img.width);
      return img.width >= width && img.height >= height;
    }) || images[images.length - 1];

    console.log({ images, optimalImage, width, height })

    setImage(optimalImage);
  }, [ref, images]);

  const spacer = image ? ((image.height / image.width * 100) + '%') : 'auto';

  const handleLoad = useCallback(() => {
    setLoaded(true);
  });


  return (
    <div ref={ref} title={title} className={cn(
      className,
      'image',
      { 'image--is-loaded': loaded }
    )} style={{
      backgroundImage: image && `url(${src.placeholder})`,
      ...style
    }}>
      <LazyLoad debounce={50} once offset={300}>
        <div>
          { loaded && <div className="image__spacer" style={{ paddingTop: spacer }} /> }
          { image && <img src={image.path} onLoad={handleLoad} alt={alt} /> }
        </div>
      </LazyLoad>
      { !loaded && <div className="image__spacer" style={{paddingTop: spacer}} /> }
      <noscript>
        {image && <img src={image.src} alt={alt} />}
      </noscript>
    </div>
  );
};




class Image extends React.Component {
  state = {
    loaded: false,
    path: null
  };

  componentDidMount() {
    this.setState({
      path: this.getPath()
    });
  }

  getPath() {

    console.log('img', this.props);

    if (!this.props.src) {
      return null;
    }

    const width = this.refs.image.clientWidth;
    const height = this.refs.image.clientHeight;
    const { images } = this.props.src;
    const ratio = window.devicePixelRatio;
    return (images.find((img, i) => {
      if (img.width * ratio >= width && img.height * ratio >= height) {
        return true;
      }
    }) || images[images.length - 1]).path;
  }

  render () {
    const image = this.props.src;
    const src = image ? image.images[0].path : undefined;
    const alt = this.props.alt || "";

    const spacer = image ? ((image.height / image.width * 100) + '%') : 'auto';
    const { loaded, path } = this.state;

    return (
      <div ref="image" title={this.props.title} className={cn(
        this.props.className,
        'image',
        {'image--is-loaded': loaded})
      } style={{
        backgroundImage: image && `url(${image.placeholder})`,
        ...this.props.style
      }}>
        <LazyLoad debounce={50} once offset={300}>
          <div>
            { loaded && <div className="image__spacer" style={{paddingTop: spacer}} /> }
            { image && <img src={path} onLoad={() => this.setState({loaded: true})} alt={alt} /> }
          </div>
        </LazyLoad>
        { !loaded && <div className="image__spacer" style={{paddingTop: spacer}} /> }
        <noscript>
          {image && <img src={image.src} alt={alt} />}
        </noscript>
      </div>
    )
  }
}
