import React from 'react';
import ReactDOM from "react-dom";
import Body from 'Body';
import './style.scss';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';

const modalRootElement = document.getElementById('modal-root');
const body = document.body;

export default class ProjectPopup extends React.Component {

  getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
  }

  constructor(props){
    super(props);
    this.esc = this.esc.bind(this);
  }

  esc(event){
    if(event.keyCode === 27) {
      this.props.onClose();
    }
  }

  componentDidMount(){
    ReactTooltip.rebuild()
    document.addEventListener("keydown", this.esc, false);
    body.style.marginRight = this.getScrollbarWidth() + 'px';
    body.style.overflow = "hidden";
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.esc, false);
    body.style.overflow = null;
    body.style.marginRight = 0;
  }

  render () {
    return ReactDOM.createPortal(
      <div onMouseDown={this.props.onClose} className={cn("dialog", this.props.className)}>
        <Body onMouseDown={e => e.stopPropagation()} className="dialog__body">{this.props.children}</Body>
      </div>,
      modalRootElement
    )
  }
}
