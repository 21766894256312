import React from 'react';
import cn from 'classnames';
import './style.scss';

export default (props) => {
  return (
    <textarea
      className={cn("textarea", {'textarea--error': props.error})}
      {...props}
    >{props.children}</textarea>
  )
};
