import React from 'react';
import Section from 'Section';
import Link from 'Link';
import Icon from 'Icon';
import Page from 'Page';
import Image from 'Image';
import { Row, Col } from 'Grid';
import { GoogleMap, Marker } from 'GoogleMap';
import './style.scss';

export default (props) => {
  return (
    <Page
      title="Контакты"
      description="Контакты студии Tour-360"
      keywords={['контакты', 'телефон', 'адрес', 'email', 'сотрудники']}
    >
      <Section>
        <h1>Контакты</h1>
        <Row className="contacts-page__row">
          <Col>
            <GoogleMap defaultZoom={13} defaultCenter={{ lat: 55.708, lng: 37.63 }}>
              <Marker position={{ lat: 55.703848, lng: 37.647976 }} />
            </GoogleMap>
          </Col>
          <Col className="contacts-page__contacts">
            <h2 className="phone">+7 495 015 83 60</h2>
            <h2 className="email"><Link href="mailto:mail@tour-360.ru">mail@tour-360.ru</Link></h2>
            <hr />
            <h3>Адрес офиса:</h3>
            <p>Автозаводская улица, 14, Москва, 115280,<br />офис 907 (этаж 9)</p>
            <ul>
              <li><Icon name="metro_line" /> Автозаводская <span><Icon name="walk" /> 850 м</span></li>
              <li><Icon name="metro_mcc" /> МЦК ЗИЛ <span><Icon name="walk" /> 790 м</span></li>
              <li><Icon name="metro_mcc" /> МЦК Автозаводская <span><Icon name="walk" /> 1,2 км</span></li>
            </ul>
            <h3>Время работы:</h3>
            <p>По будням с 9:00 по 18:00</p>
          </Col>
        </Row>
        <ul className="contacts-page__staff">
          <li>
            <Image src={require('assets/images/maxim.jpg')} />
            <div className="desc">
              <h3>Максим Бородин</h3>
              <span className="position">Менеджер по работе с клиентами</span>
              <Link href="mailto:maxim@tour-360.ru">maxim@tour-360.ru</Link>
              <span className="phone">доб. 102</span>
            </div>
          </li>
          <li>
            <Image src={require('assets/images/denis.jpg')} />
            <div className="desc">
              <h3>Денис Бородин</h3>
              <span className="position">Фотограф, ИТ-Специалист</span>
              <Link href="mailto:denis@tour-360.ru">denis@tour-360.ru</Link>
              <span className="phone">доб. 101</span>
            </div>
          </li>
          <li>
            <Image src={require('assets/images/maxim-spb.jpg')} />
            <div className="desc">
              <h3>Максим Малышев</h3>
              <span className="position">Фотограф в Санкт-Петербурге</span>
              <Link href="mailto:malyshev@tour-360.ru">malyshev@tour-360.ru</Link>
              <span className="phone">+7 931 202-39-79</span>
            </div>
          </li>
          {/*<li>
            <Image src={require('assets/images/anonim.jpg')} />
            <div className="desc">
              <h3>Еще Специалист</h3>
              <span className="position">Специалист</span>
              <Link href="mailto:denis@tour-360.ru">more@tour-360.ru</Link>
              <span className="phone">доб. 103</span>
            </div>
          </li>*/}
        </ul>
      </Section>
    </Page>
  )
}
