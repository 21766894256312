import goal from 'libs/goal.js';


export default (dispatch) => ({
  openCalcPopup: () => {
    goal('popup-calc');
    dispatch({type: 'CALC_POPUP_OPEN'})
  },
  closeCalcPopup: () => dispatch({type: 'CALC_POPUP_CLOSE'})
});
