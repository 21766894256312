import React from 'react';
import Dialog from 'Dialog';
import Icon from 'Icon';
import Form from './form.js';
import { reset, SubmissionError } from 'redux-form';
import { connect } from 'react-redux'
import api from 'libs/api';
import goal from 'libs/goal';

import './style.scss';

export default connect()(class OrderPopup extends React.Component {
  state = {
    isSubmit: false,
    calc: false
  }

  render() {
    const { props, state } = this;
    return (
      <Dialog className="calc-popup" onClose={props.onClose} open={props.open}>
        <div onClick={props.onClose} className="order-popup__close-button">
          <Icon name="cross" />
        </div>
        {state.isSubmit ? (
          <div>
            <h2>Спасибо, {state.name}!</h2>
            <p>Заявка успешно отправлена. В скором времени с вами свяжеться наш специалист.</p>
          </div>
        ) : (
          <div>
            <h2>Расчет стоимости создания 3D тура</h2>
            <Form onSubmit={this.handleSubmit} />
          </div>
        )}
      </Dialog>
    )
  }

  handleSubmit = (data) => {
    return api.post('/api/order', data).then(() => {
      this.setState({isSubmit: true, name: data.name});
      this.props.dispatch(reset('order'));
      goal('popup-calc');

    }).catch(() => {
      throw new SubmissionError({ _error: 'Ошибка отправки формы!' })
    })
  }
});
