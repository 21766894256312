import React from 'react'
import cn from 'classnames'
import './style.scss';

export default (props) => {
  const svg = require(`../../../assets/icons/${props.name}.svg`).default;
  return (
    <svg className={cn("icon", "icon--" + props.name, props.className)}>
      <use xlinkHref={`#${svg.id}`} />
    </svg>
  );
}
