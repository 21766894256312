import { createStore, combineReducers, applyMiddleware } from 'redux';
import { reducer as formReducer } from 'redux-form';
import logger from 'redux-logger'
import orderReducer from './reducers/order.js';
import calcReducer from './reducers/calc.js';

const reducers = combineReducers({
  form: formReducer,
  order: orderReducer,
  calc: calcReducer
})

const localStorageItem = localStorage.getItem('reduxState');
const persistedState = localStorageItem ? JSON.parse(localStorageItem) : {}

const store = createStore(
  reducers,
  persistedState,
  applyMiddleware(logger)
);

store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})

export default store;
