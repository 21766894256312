import React from 'react';
import InputRange from 'react-input-range';
// import 'react-input-range/lib/css/index.css';
import './style.scss';

export default props => (
  <InputRange {...props} />
)


