import React from 'react'
import cn from 'classnames';
import './style.scss';

export default (props) => {
  return <button {...{
    onClick: props.onClick
  }} className={
    cn(
      'button',
      props.className,
      {
        'button--big': props.big,
        'button--small': props.small,
        'button--dark': props.dark,
        'button--lite': props.lite,
        'button--backlight': props.backlight
      }
    )}>{props.children}</button>;
}
