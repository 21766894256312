import React from 'react';
import Section from 'Section';
import Title from 'Title';
import Link from 'Link';
import { connect } from 'react-redux';
import orderActions from 'actions/order.js';
import calcActions from 'actions/calc.js';
import './style.scss';

export default connect(null, (dispatch) => ({
  ...orderActions(dispatch),
  ...calcActions(dispatch)
}))(props => {
  return (
    <Section className="steps">
      <Title id="steps" lavel={2}>Как мы работаем?</Title>
      <ul className="steps__list">
        <li>
          <div className="steps__list__number"><span>1</span></div>
          <div className="steps__list__content">
            <strong>Заявка</strong>
            <p>Вы <Link onClick={props.openOrderPopup}>оставляете заявку</Link> на расчет стоимости виртуального тура.</p>
          </div>
        </li>
        <li>
          <div className="steps__list__number"><span>2</span></div>
          <div className="steps__list__content">
            <strong>Расчет стоимости</strong>
            <p>В этот же день мы рассчитываем стоимость и высылаем вам договор на согласование.</p>
          </div>
        </li>
        <li>
          <div className="steps__list__number"><span>3</span></div>
          <div className="steps__list__content">
            <strong>Съемка</strong>
            <p>Наш фотограф проводит съемку в удобное для вас время. Как правило, съемка длиться 1-2 часа.</p>
          </div>
        </li>
        <li>
          <div className="steps__list__number"><span>4</span></div>
          <div className="steps__list__content">
            <strong>Прием работы</strong>
            <p>Мы презентуем готовый проект. Вы принимаете и оплачиваете работу.</p>
          </div>
        </li>
      </ul>
    </Section>
  )
});
