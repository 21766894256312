import React from 'react'
import './style.scss';
import cn from 'classnames';

const Row = (props) => {
  return <div {...props} className={cn("row", props.className)} />;
}

const Col = (props) => {
  return <div {...props} className={cn("col", props.className)} />;
}

export { Row, Col }
