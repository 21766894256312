import React from 'react';
import { Route } from "react-router-dom";
import Article from "./Article";

export default () => {
  return(
    <>
      {/*<Article />*/}
      <Route path="/blog/a" component={() => 1} />
    </>
  )
}
